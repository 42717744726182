import React from "react";

export default function AutonomousAgentPlatform() {
  return (
    <div className="bg-[#F3F5F9] py-20">
      <section className=" mx-auto max-w-screen-xl pb-4 px-4 sm:px-8">
        <div className="text-center space-y-4">
          <h1 className="text-gray-800 font-bold text-4xl md:text-5xl">
            GenAI Autonomous
            <span className="text-indigo-600">  Agent Platform</span>
          </h1>
          <p className="text-black font-bold mx-auto leading-4">
            SproutsAI is a generative AI-powered autonomous agent platform to
            revolutionize enterprise automation.
          </p>
          <p className="text-gray-500 max-w-xl mx-auto leading-relaxed">
            Leverage the extreme power of Generative AI to automate your end to
            end hiring process. The same platform can also be used to automate
            sales and marketing lead generation, and to automate customer care
            workflows.
          </p>
        </div>
      </section>
      <section className="py-14">
            <div className="max-w-screen-xl mx-auto md:px-8">
                <div className="items-center gap-x-12 sm:px-4 md:px-0 lg:flex">
                    <div className="flex-1 sm:hidden lg:block">
                        <img src="https://sproutsweb-assets.s3.amazonaws.com/images/agent-ai.png"
                         className="md:max-w-lg sm:rounded-lg" alt="" />
                    </div>
                    <div className="max-w-xl px-4 space-y-3 mt-6 sm:px-0 md:mt-0 lg:max-w-2xl">
                        <h3 className="text-indigo-600 font-semibold">
                            SproutsAI Agents
                        </h3>
                        <p className="text-gray-800 text-3xl font-semibold sm:text-4xl">
                        A Glimpse into the Future, A New Era of Automation
                        </p>
                        <p className="mt-3 text-gray-600">
                        Our agents are meticulously built over the last two years and represent a significant leap forward in autonomous agent technology. These agents embody the pinnacle of efficiency and adaptability, capable of operating 24/7, at scale, and with significantly reduced costs.

                        </p>
                        <p className="mt-3 text-gray-600">
                        SproutsAI agents are fully explainable, and data-driven, coupled with their ability to collaborate and adapt to dynamic environments, making them exceptionally versatile and resilient. 

                        </p>
                        
                    </div>
                </div>
            </div>
        </section>
    </div>
  );
}
