import React from 'react'

export default function NoCodeBuilder() {
    return (
        <div className='bg-[#F3F5F9] py-20'>
            <section className=" mx-auto max-w-screen-xl pb-4 px-4 sm:px-8">
                <div className="text-center space-y-4">
                    <h1 className="text-gray-800 font-bold text-4xl md:text-5xl">
                        No Code
                        <span className="text-indigo-600"> Builder</span>
                    </h1>
                    <p className="text-gray-500 max-w-xl mx-auto leading-relaxed">
                        Build and deploy AI assistants
                    </p>
                </div>
                <section className=" bg-[#FFF] rounded-[12px] py-4 px-4 md:px-8 mt-[40px] ">
                    <div className="max-w-xl">
                        <div className="py-4">
                            <h3 className="text-3xl text-gray-800 font-semibold md:text-4xl">
                                Build and deploy  <span className="text-indigo-600">AI agents</span>
                            </h3>
                            <p className="text-gray-500 leading-relaxed mt-3">
                                SproutsAI is a no-code platform that enables users to build generative AI Agents, Integration using their own data. With No Code Builder Studio, users can easily create powerful automation agents without the need for coding skills.
                            </p>
                        </div>
                        {/* text-[#fff] border border-solid bg-[#1369E9] hover:border-[#145ecc] hover:bg-[#145ecc] hover:shadow-jobPostButtonShadow   focus:border-[#4287ED] focus:bg-[#4287ED] focus:shadow-buttonFocusShadow  focus:outline-none */}
                        <a
                            className="bg-[#1369E9] border border-solid text-[#fff] inline-flex items-center  px-[18px]
                            py-[12px]
                             rounded-lg    justify-center  text-[18px] not-italic font-normal hover:border-[#145ecc] hover:bg-[#145ecc] hover:shadow-jobPostButtonShadow focus:border-[#4287ED] focus:bg-[#4287ED] focus:shadow-buttonFocusShadow  focus:outline-none"
                            href="https://beta.sproutsai.com/signup"
                            target='_blank'
                            >
                            Create custom Agent
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-1 duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                            </svg>
                        </a>
                    </div>

                    <style jsx>{`
                .cta-pr-btn:hover svg {
                    transform: translateX(5px)
                }
            `}</style>
                </section>
            </section>

        </div>
    )
}
