import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SuccessModal from "../components/modal/SuccessModal";
import { useLocation } from "react-router";

const FaqsCard = (props) => {
  const answerElRef = useRef();
  const [state, setState] = useState(false);
  const [answerH, setAnswerH] = useState("0px");
  const { faqsList, idx } = props;

  const handleOpenAnswer = () => {
    const answerElH = answerElRef.current.childNodes[0].offsetHeight;
    setState(!state);
    setAnswerH(`${answerElH + 20}px`);
  };

  return (
    <div
      className="space-y-3 mt-5 overflow-hidden border-b"
      key={idx}
      onClick={handleOpenAnswer}
    >
      <h4 className="cursor-pointer pb-5 flex items-center justify-between text-lg text-gray-700 font-medium">
        {faqsList.q}
        {state ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-gray-500 ml-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M20 12H4"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-gray-500 ml-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 4v16m8-8H4"
            />
          </svg>
        )}
      </h4>
      <div
        ref={answerElRef}
        className="duration-300"
        style={state ? { height: answerH } : { height: "0px" }}
      >
        <div>
          <p className="text-gray-500">{faqsList.a}</p>
        </div>
      </div>
    </div>
  );
};

export const Pricing = () => {
  const plans = [
    {
      name: "Starter",
      button: "Contact us",
      desc: "Self-serve plan with limited functionality and features. Great to get started.",
      price: "Custom Pricing",
      isMostPop: false,
      features: [
        // "Free Setup for one job",
        "Get started within mins",
        "Limited AI",
      ],
    },
    {
      name: "Pro",
      button: "Contact us",
      desc: "Self-serve plan with more features, great for fast moving teams",
      price: "Custom Pricing",
      isMostPop: false,
      features: [
        "100 Sourcing & Matching",
        "Full power AI Agents",
        "Personalized email outreach",
        "15 personalized interview kit",
      ],
    },
    {
      name: "Enterprise",
      button: "Contact us",
      desc: "Full access to all features, perfect for fast-moving teams and large organizing",
      price: "Custom Pricing",
      isMostPop: true,
      features: [
        "ATS Integration",
        "AI Adaptive Interview",
        "Unlimited jobs/candidates",
        "Enterprise grade security",
        "Onboarding & training support",
      ],
    },
  ];

  const faqsList = [
    {
      q: "How do I get started",
      a: "Getting started on SproutsAI is easy - simply sign up and you'll be boarded onto our Hiring Studio Solution. From there, you’ll be able to post jobs and see top quality candidates automatically sourced by our AI agents. Review and start interviewing, it is that easy.",
    },
    {
      q: "Why take a demo?",
      a: "Your hiring processes and workflows are unique and critical to your entire team’s efficiency. We therefore feel it is important to invest a short amount of time upfront to ensure sproutsAI is the right fit to set you up for success.",
    },
    {
      q: "What are my payment options?",
      a: "You can select a monthly or annual plan. Annual plans include 20% savings relative to the equivalent monthly option. Monthly plans require payment by credit card, while annual plans can be paid by credit card, direct deposit, and wire transfers.",
    },
    {
      q: "Do you charge for integrations?",
      a: "No. And if we are missing an integration, we are happy to discuss adding it. Reach out at sales@sproutsai.com to request an integration.",
    },
    {
      q: "What ATS provider the solution integrates with?",
      a: "Greenhouse, Wellfound and LinkedIn. We are integrating with new ATS tools currently, so please reach out if you are interested in a specific integration.",
    },
    {
      q: "How do I cancel my plan?",
      a: "You can reach out to support@sproutsai.com, or contact your dedicated customer success manager if you need to cancel your plan.",
    },
  ];
  const nav = useNavigate()



  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    companySize: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const loc = useLocation()
  const [showmsg, setShowmsg] = useState(false)

  const handleSubmit = () => {


    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      firstname: formData.firstName ,
      lastname: formData.lastName,
      name: formData.firstName + " " + formData.lastName,
      email: formData.email,
      message:  ("company : " + formData.companyName ? formData.companyName :"" ) + ("company size : " + formData.companySize ? formData.companySize :"" ),
      phone: formData.phone,
      forReq: loc?.search === "?demo" ? "demo" : loc?.search === "?webinar" ? "webinar" : "contact",
      subject: "Contact Request - SproutsAI",
      to: "contacts@sproutsai.com",
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://api.sproutsai.com/controls/send-demo-mail", requestOptions)
      .then(response => response.text())
      .then(result => {

        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          message: "",
          phone: "",
        });
      })
      .catch(error => console.error('Error:', error));
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      {/* <div className="bg-[#F4F5EF]"> */}
      <div className="bg-[#F3F5F9]">
        <section className="py-14">
          <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
            <div className="relative max-w-xl mx-auto sm:text-center">
              <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
                Pricing
              </h3>
              <div className="mt-3 max-w-xl">
                <p>Flexible pricing to provide maximum benefits to you</p>
              </div>
            </div>

            <div class="container px-6 py-12 mx-auto">
              <div class="grid grid-cols-1 gap-12 mt-10 lg:grid-cols-2">
                <div class="">
                  <h1 className="text-gray-800 text-3xl font-semibold sm:text-3xl leading-10 mb-[40px]">
                    Begin recruiting top <br /> talent more <b> efficiently...</b> <br />starting now!
                  </h1>
                  <span className="text-gray-800 text-xl font-normal not-italic sm:text-xl leading-10 ">Our pricing is tailored to suit your needs, whether you're a boutique recruiting agency or a multinational corporation. To generate a personalized proposal, we require some details from you. Kindly complete the form, and a member of our team will reach out to you soon.</span>

                </div>

                <div class="p-4 py-6 rounded-lg bg-[#FFF] shadow-CardsShadow md:p-8">
                  {showmsg ? (
                    <div className="flex h-full justify-center items-center  flex-col">
                      <h1 className="text-gray-800 text-3xl font-semibold sm:text-3xl leading-10 mb-[20px]">
                        Thank you!
                      </h1>
                      <span className="text-gray-800 text-xl font-normal not-italic sm:text-xl ">
                        Your submission has been received!
                      </span>
                    </div>
                  ) : (
                    <form

                      onSubmit={e => {
                        e.preventDefault()
                        setIsModalOpen(true)
                        handleSubmit()
                      }
                      }>
                      <div className="-mx-2 md:items-center md:flex">
                        <div className="flex-1 px-2">
                          <label className="block mb-2 text-sm text-gray-600 ">
                            First Name
                          </label>
                          <input
                            required
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            placeholder="John"
                            className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg    focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                          />
                        </div>

                        <div className="flex-1 px-2 mt-4 md:mt-0">
                          <label className="block mb-2 text-sm text-gray-600 ">
                            Last Name
                          </label>
                          <input
                            type="text"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            placeholder="Doe"
                            className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg  focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                          />
                        </div>
                      </div>


                      <div className="mt-4">
                        <label className="block mb-2 text-sm text-gray-600 ">
                          Work email
                        </label>
                        <input
                          required
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          placeholder="johndoe@example.com"
                          className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg  focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                      </div>
                      <div className="-mx-2 md:items-center md:flex mt-4">
                        <div className="flex-1 px-2">
                          <label className="block mb-2 text-sm text-gray-600 ">
                            Company name

                          </label>
                          <input
                            required
                            type="text"
                            name="companyName"
                            value={formData?.companyName}
                            onChange={handleChange}
                            placeholder=""
                            className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg    focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                          />
                        </div>

                        <div className="flex-1 px-2 mt-4 md:mt-0">
                          <label className="block mb-2 text-sm text-gray-600 ">
                            Company size
                          </label>
                          <input
                            type="number"
                            name="companySize"
                            value={formData?.companySize}
                            onChange={handleChange}
                            placeholder=""
                            className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg  focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                          />
                        </div>
                      </div>
                      <button type="submit" className="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-lg hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50">
                        Get pricing
                      </button>
                    </form>
                  )}
                </div>
              </div>
            </div>

            <div className="flex justify-center mt-[40px]">
              <div className=''>
                <h1 class="text-2xl font-semibold text-gray-800 capitalize lg:text-3xl">
                  Our Pricing Packages :
                </h1>

                <div class="mt-2">
                  <span class="inline-block w-40 h-1 bg-blue-500 rounded-full"></span>
                  <span class="inline-block w-3 h-1 ml-1 bg-blue-500 rounded-full"></span>
                  <span class="inline-block w-1 h-1 ml-1 bg-blue-500 rounded-full"></span>
                </div>
              </div>
            </div>
            <div className="mt-16 justify-center gap-6 sm:grid sm:grid-cols-2 sm:space-y-0 lg:grid-cols-3">
              {plans.map((item, idx) => (
                <div
                  key={idx}
                  className={`relative flex-1 flex items-stretch flex-col bg-[#FFF] rounded-[12px] shadow-CardsShadow mt-6 sm:mt-0 ${item.isMostPop ? "mt-10" : ""
                    }`}
                >
                  {item.isMostPop ? (
                    <span class="w-32 absolute -top-5 left-0 right-0 mx-auto px-3 py-2 rounded-full border shadow-md bg-white text-center text-gray-700 text-sm font-semibold">
                      Most popular
                    </span>
                  ) : (
                    ""
                  )}
                  <div className="p-8  border-b flex flex-col justify-between  h-[170px]">
                    <div className="space-y-4">
                      <span className="text-indigo-600 font-medium">
                        {item.name}
                      </span>
                      {/* <div className='text-gray-800 text-3xl font-semibold'>
                                        ${item.price} <span className="text-xl text-gray-600 font-normal">/mo</span>
                                    </div> */}
                      {/* <div className="text-gray-800 text-3xl font-semibold">
                        {item.price == "250" && <span>$</span>}
                        {item.price}
                        {item.price == "250" && (
                          <span className="text-xl text-gray-600 font-normal">
                            /jobs
                          </span>
                        )}
                      </div> */}

                      <p>{item.desc}</p>
                    </div>
                    {/* {item?.button === "Contact us" ? (
                      <button className="px-3 py-3 rounded-lg w-full font-semibold text-sm duration-150 text-white bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700"
                        onClick={e => nav("/contact")}
                      >
                        {item?.button}
                      </button>
                    ) : (
                      <a className="px-3 py-3 flex justify-center rounded-lg w-full font-semibold text-sm duration-150 text-white bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700"
                        href="https://beta.sproutsai.com/signup"
                        target="_blank"
                      >
                        {item?.button}
                      </a>
                    )} */}
                  </div>
                  <ul className="p-8 space-y-3">
                    <li className="pb-2 text-gray-800 font-medium">
                      <p>Features</p>
                    </li>
                    {item.features.map((featureItem, idx) => (
                      <li key={idx} className="flex items-center gap-5">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 text-indigo-600"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        {featureItem}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>



          </div>
        </section>

        <section className="leading-relaxed max-w-screen-xl mt-12 p-10 mx-auto px-4 md:px-8">
          <div className="space-y-3 text-center">
            <h1 className="text-3xl text-gray-800 font-semibold">
              Frequently Asked Questions
            </h1>
            <p className="text-gray-600 max-w-lg mx-auto text-lg">
              Answered all frequently asked questions, Still confused? feel free
              to contact us.
            </p>
          </div>
          <div className="mt-14 max-w-2xl mx-auto">
            {faqsList.map((item, idx) => (
              <FaqsCard idx={idx} faqsList={item} />
            ))}
          </div>
        </section>
      </div>
    </>
  );
};
