// import React, { useState } from 'react';
// import axios from 'axios';

// const JobOpen = () => {
//     const [form, setForm] = useState({
//         title: '',
//         description: '',
//         identifierName: '',
//         identifierValue: '',
//         datePosted: '',
//         validThrough: '',
//         employmentType: '',
//         hiringOrganizationName: '',
//         hiringOrganizationSameAs: '',
//         streetAddress: '',
//         addressLocality: '',
//         addressRegion: '',
//         postalCode: '',
//         addressCountry: '',
//         currency: '',
//         salaryValue: '',
//         salaryUnit: '',
//         jobLocationType: '',
//         applicantLocationRequirements: '',
//         experienceRequirements: '',
//         educationRequirements: '',
//         qualifications: '',
//         skills: '',
//         industry: '',
//         incentives: '',
//         responsibilities: '',
//         workHours: '',
//         jobBenefits: ''
//     });

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setForm({ ...form, [name]: value });
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();

//         const jobData = {
//             title: form.title,
//             description: form.description,
//             identifier: {
//                 name: form.identifierName,
//                 value: form.identifierValue
//             },
//             datePosted: form.datePosted,
//             validThrough: form.validThrough,
//             employmentType: form.employmentType,
//             hiringOrganization: {
//                 name: form.hiringOrganizationName,
//                 sameAs: form.hiringOrganizationSameAs
//             },
//             jobLocation: {
//                 streetAddress: form.streetAddress,
//                 addressLocality: form.addressLocality,
//                 addressRegion: form.addressRegion,
//                 postalCode: form.postalCode,
//                 addressCountry: form.addressCountry
//             },
//             baseSalary: {
//                 currency: form.currency,
//                 value: {
//                     value: form.salaryValue,
//                     unitText: form.salaryUnit
//                 }
//             },
//             jobLocationType: form.jobLocationType,
//             applicantLocationRequirements: {
//                 type: form.applicantLocationRequirements
//             },
//             experienceRequirements: form.experienceRequirements,
//             educationRequirements: form.educationRequirements,
//             qualifications: form.qualifications,
//             skills: form.skills,
//             industry: form.industry,
//             incentives: form.incentives,
//             responsibilities: form.responsibilities,
//             workHours: form.workHours,
//             jobBenefits: form.jobBenefits
//         };

//         axios.post('http://localhost:3000/job', jobData)
//             .then(response => {
//                 console.log('Job posted successfully:', response.data);
//                 // Clear the form or show success message
//             })
//             .catch(error => {
//                 console.error('There was an error posting the job:', error);
//             });
//     };

//     return (
//         <>
//             <div className='py-8 px-2 bg-white'>
//                 <div className="max-w-4xl mx-auto p-8 bg-gray-100 border-gray-100 shadow-md rounded-lg">
//                     <h2 className="text-3xl font-bold mb-8 text-gray-800">Post a Job</h2>
//                     <form onSubmit={handleSubmit} className="space-y-6">
//                         <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
//                             <div>
//                                 <label className="block text-sm font-medium text-gray-700">Job Title</label>
//                                 <input type="text" name="title" value={form.title} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
//                             </div>
//                             <div>
//                                 <label className="block text-sm font-medium text-gray-700">ID</label>
//                                 <input type="text" name="identifierValue" value={form.identifierValue} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
//                             </div>
//                         </div>
//                         <div>
//                             <label className="block text-sm font-medium text-gray-700">Description</label>
//                             <textarea name="description" value={form.description} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" rows="4" required></textarea>
//                         </div>
//                         <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
//                             <div>
//                                 <label className="block text-sm font-medium text-gray-700">Date Posted</label>
//                                 <input type="date" name="datePosted" value={form.datePosted} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
//                             </div>
//                             <div>
//                                 <label className="block text-sm font-medium text-gray-700">Valid Through</label>
//                                 <input type="date" name="validThrough" value={form.validThrough} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
//                             </div>
//                         </div>
//                         <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
//                             <div>
//                                 <label className="block text-sm font-medium text-gray-700">Employment Type</label>
//                                 <select name="employmentType" value={form.employmentType} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required>
//                                     <option value="">Select Type</option>
//                                     <option value="FULL_TIME">Full Time</option>
//                                     <option value="PART_TIME">Part Time</option>
//                                     <option value="CONTRACTOR">Contractor</option>
//                                     <option value="TEMPORARY">Temporary</option>
//                                     <option value="INTERN">Intern</option>
//                                     <option value="VOLUNTEER">Volunteer</option>
//                                     <option value="PER_DIEM">Per Diem</option>
//                                     <option value="OTHER">Other</option>
//                                 </select>
//                             </div>
//                             <div>
//                                 <label className="block text-sm font-medium text-gray-700">Hiring Organization Name</label>
//                                 <input type="text" name="hiringOrganizationName" value={form.hiringOrganizationName} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
//                             </div>
//                         </div>
//                         <div>
//                             <label className="block text-sm font-medium text-gray-700">Job Location</label>
//                             <input type="text" name="addressLocality" value={form.addressLocality} onChange={handleChange} placeholder="City" className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50 mb-2" required />
//                             <input type="text" name="addressRegion" value={form.addressRegion} onChange={handleChange} placeholder="State/Province" className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50 mb-2" />
//                             <input type="text" name="postalCode" value={form.postalCode} onChange={handleChange} placeholder="Postal Code" className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50 mb-2" />
//                             <input type="text" name="addressCountry" value={form.addressCountry} onChange={handleChange} placeholder="Country" className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
//                         </div>
//                         <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
//                             <div>
//                                 <label className="block text-sm font-medium text-gray-700">Base Salary Currency</label>
//                                 <input type="text" name="currency" value={form.currency} onChange={handleChange} placeholder="e.g., USD" className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
//                             </div>
//                             <div>
//                                 <label className="block text-sm font-medium text-gray-700">Base Salary Value</label>
//                                 <input type="number" name="salaryValue" value={form.salaryValue} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
//                             </div>
//                         </div>
//                         <div>
//                             <label className="block text-sm font-medium text-gray-700">Base Salary Unit</label>
//                             <select name="salaryUnit" value={form.salaryUnit} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
//                                 <option value="">Select Unit</option>
//                                 <option value="HOUR">Hour</option>
//                                 <option value="DAY">Day</option>
//                                 <option value="WEEK">Week</option>
//                                 <option value="MONTH">Month</option>
//                                 <option value="YEAR">Year</option>
//                             </select>
//                         </div>
//                         <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
//                             <div>
//                                 <label className="block text-sm font-medium text-gray-700">Job Location Type</label>
//                                 <select name="jobLocationType" value={form.jobLocationType} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
//                                     <option value="">Select Type</option>
//                                     <option value="TELECOMMUTE">Telecommute</option>
//                                 </select>
//                             </div>
//                             <div>
//                                 <label className="block text-sm font-medium text-gray-700">Applicant Location Requirements</label>
//                                 <input type="text" name="applicantLocationRequirements" value={form.applicantLocationRequirements} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
//                             </div>
//                         </div>
//                         <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
//                             <div>
//                                 <label className="block text-sm font-medium text-gray-700">Experience Requirements</label>
//                                 <input type="text" name="experienceRequirements" value={form.experienceRequirements} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
//                             </div>
//                             <div>
//                                 <label className="block text-sm font-medium text-gray-700">Education Requirements</label>
//                                 <input type="text" name="educationRequirements" value={form.educationRequirements} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
//                             </div>
//                         </div>
//                         <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
//                             <div>
//                                 <label className="block text-sm font-medium text-gray-700">Qualifications</label>
//                                 <input type="text" name="qualifications" value={form.qualifications} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
//                             </div>
//                             <div>
//                                 <label className="block text-sm font-medium text-gray-700">Skills</label>
//                                 <input type="text" name="skills" value={form.skills} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
//                             </div>
//                         </div>
//                         <div>
//                             <label className="block text-sm font-medium text-gray-700">Industry</label>
//                             <input type="text" name="industry" value={form.industry} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
//                         </div>
//                         <div>
//                             <label className="block text-sm font-medium text-gray-700">Incentives</label>
//                             <input type="text" name="incentives" value={form.incentives} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
//                         </div>
//                         <div>
//                             <label className="block text-sm font-medium text-gray-700">Responsibilities</label>
//                             <textarea name="responsibilities" value={form.responsibilities} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" rows="4"></textarea>
//                         </div>
//                         <div>
//                             <label className="block text-sm font-medium text-gray-700">Work Hours</label>
//                             <input type="text" name="workHours" value={form.workHours} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
//                         </div>
//                         <div>
//                             <label className="block text-sm font-medium text-gray-700">Job Benefits</label>
//                             <textarea name="jobBenefits" value={form.jobBenefits} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" rows="4"></textarea>
//                         </div>
//                         <div className="text-right">
//                             <button type="submit" className="px-6 py-3 bg-blue-600 text-white font-medium rounded-md shadow-sm hover:bg-blue-500 focus:outline-none focus:ring focus:ring-indigo-200 focus:ring-opacity-50">Submit</button>
//                         </div>
//                     </form>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default JobOpen;



import React, { useState } from 'react';
import axios from 'axios';

const JobOpen = () => {
    const [form, setForm] = useState({
        title: '',
        description: '',
        identifierName: '',
        identifierValue: '',
        datePosted: '',
        validThrough: '',
        employmentType: '',
        hiringOrganizationName: '',
        hiringOrganizationSameAs: '',
        streetAddress: '',
        addressLocality: '',
        addressRegion: '',
        postalCode: '',
        addressCountry: '',
        currency: '',
        salaryValue: '',
        salaryUnit: '',
        jobLocationType: '',
        applicantLocationRequirements: '',
        experienceRequirements: '',
        educationRequirements: '',
        qualifications: '',
        skills: '',
        industry: '',
        incentives: '',
        responsibilities: '',
        workHours: '',
        jobBenefits: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const jobData = {
            title: form.title,
            description: form.description,
            identifier: {
                name: form.identifierName,
                value: form.identifierValue
            },
            datePosted: form.datePosted,
            validThrough: form.validThrough,
            employmentType: form.employmentType,
            hiringOrganization: {
                name: form.hiringOrganizationName,
                sameAs: form.hiringOrganizationSameAs
            },
            jobLocation: {
                streetAddress: form.streetAddress,
                addressLocality: form.addressLocality,
                addressRegion: form.addressRegion,
                postalCode: form.postalCode,
                addressCountry: form.addressCountry
            },
            baseSalary: {
                currency: form.currency,
                value: {
                    value: form.salaryValue,
                    unitText: form.salaryUnit
                }
            },
            jobLocationType: form.jobLocationType,
            applicantLocationRequirements: {
                type: form.applicantLocationRequirements
            },
            experienceRequirements: form.experienceRequirements,
            educationRequirements: form.educationRequirements,
            qualifications: form.qualifications,
            skills: form.skills,
            industry: form.industry,
            incentives: form.incentives,
            responsibilities: form.responsibilities,
            workHours: form.workHours,
            jobBenefits: form.jobBenefits
        };

        axios.post('https://gboard.onrender.com/job', jobData)
            .then(response => {
                console.log('Job posted successfully:', response.data);
                setForm({
                    title: '',
                    description: '',
                    identifierName: '',
                    identifierValue: '',
                    datePosted: '',
                    validThrough: '',
                    employmentType: '',
                    hiringOrganizationName: '',
                    hiringOrganizationSameAs: '',
                    streetAddress: '',
                    addressLocality: '',
                    addressRegion: '',
                    postalCode: '',
                    addressCountry: '',
                    currency: '',
                    salaryValue: '',
                    salaryUnit: '',
                    jobLocationType: '',
                    applicantLocationRequirements: '',
                    experienceRequirements: '',
                    educationRequirements: '',
                    qualifications: '',
                    skills: '',
                    industry: '',
                    incentives: '',
                    responsibilities: '',
                    workHours: '',
                    jobBenefits: ''
                });
            })
            .catch(error => {
                console.error('There was an error posting the job:', error);
            });
    };

    return (
        <>
            <div className='py-8 px-2 bg-white'>
                <div className="max-w-4xl mx-auto p-8 bg-gray-100 border-gray-100 shadow-md rounded-lg">
                    <h2 className="text-3xl font-bold mb-8 text-gray-800">Post a Job</h2>
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Job Title</label>
                                <input type="text" name="title" value={form.title} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">ID</label>
                                <input type="text" name="identifierValue" value={form.identifierValue} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Description</label>
                            <textarea name="description" value={form.description} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" rows="4" required></textarea>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Date Posted</label>
                                <input type="date" name="datePosted" value={form.datePosted} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Valid Through</label>
                                <input type="date" name="validThrough" value={form.validThrough} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
                            </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Employment Type</label>
                                <select name="employmentType" value={form.employmentType} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required>
                                    <option value="">Select Type</option>
                                    <option value="FULL_TIME">Full Time</option>
                                    <option value="PART_TIME">Part Time</option>
                                    <option value="CONTRACTOR">Contractor</option>
                                    <option value="TEMPORARY">Temporary</option>
                                    <option value="INTERN">Intern</option>
                                    <option value="VOLUNTEER">Volunteer</option>
                                    <option value="PER_DIEM">Per Diem</option>
                                    <option value="OTHER">Other</option>
                                </select>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Hiring Organization Name</label>
                                <input type="text" name="hiringOrganizationName" value={form.hiringOrganizationName} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Job Location</label>
                            <input type="text" name="addressLocality" value={form.addressLocality} onChange={handleChange} placeholder="City" className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50 mb-2" required />
                            <input type="text" name="addressRegion" value={form.addressRegion} onChange={handleChange} placeholder="State/Province" className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50 mb-2" />
                            <input type="text" name="postalCode" value={form.postalCode} onChange={handleChange} placeholder="Postal Code" className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50 mb-2" />
                            <input type="text" name="addressCountry" value={form.addressCountry} onChange={handleChange} placeholder="Country" className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Base Salary Currency</label>
                                <input type="text" name="currency" value={form.currency} onChange={handleChange} placeholder="e.g., USD" className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Base Salary Value</label>
                                <input type="number" name="salaryValue" value={form.salaryValue} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Base Salary Unit</label>
                            <select name="salaryUnit" value={form.salaryUnit} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                                <option value="">Select Unit</option>
                                <option value="HOUR">Hour</option>
                                <option value="DAY">Day</option>
                                <option value="WEEK">Week</option>
                                <option value="MONTH">Month</option>
                                <option value="YEAR">Year</option>
                            </select>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Workplace </label>
                                <select name="jobLocationType" value={form.jobLocationType} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                                    <option value="">Select Type</option>
                                    <option value="TELECOMMUTE">Remote</option>
                                    <option value="ONSITE">Onsite</option>
                                    <option value="HYBRID">Hybrid</option>
                                    {/* <option value="REMOTE">Remote</option> */}
                                    <option value="FIELD">Field</option>
                                </select>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Applicant Location Requirements</label>
                                <input type="text" name="applicantLocationRequirements" value={form.applicantLocationRequirements} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
                            </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Experience Requirements</label>
                                <input type="text" name="experienceRequirements" value={form.experienceRequirements} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Education Requirements</label>
                                <input type="text" name="educationRequirements" value={form.educationRequirements} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
                            </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Qualifications</label>
                                <input type="text" name="qualifications" value={form.qualifications} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Skills</label>
                                <input type="text" name="skills" value={form.skills} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Industry</label>
                            <input type="text" name="industry" value={form.industry} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Incentives</label>
                            <input type="text" name="incentives" value={form.incentives} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Responsibilities</label>
                            <textarea name="responsibilities" value={form.responsibilities} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" rows="4"></textarea>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Work Hours</label>
                            <input type="text" name="workHours" value={form.workHours} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Job Benefits</label>
                            <textarea name="jobBenefits" value={form.jobBenefits} onChange={handleChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50" rows="4"></textarea>
                        </div>
                        <div className="text-right">
                            <button type="submit" className="px-6 py-3 bg-blue-600 text-white font-medium rounded-md shadow-sm hover:bg-blue-500 focus:outline-none focus:ring focus:ring-indigo-200 focus:ring-opacity-50">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default JobOpen;
