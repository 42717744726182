import React from "react";
import Linkedin from '../components/assets/linkedin.png'
import { useInView } from "react-intersection-observer";





export default function Company() {
  const JobOpening = ({ position, vacancies, location }) => {
    return (
      <div className="bg-white p-4 rounded-lg shadow-md my-4">
        <h2 className="text-xl font-semibold">{position}</h2>
        <p>Vacancies: {vacancies}</p>
        <p>Location: {location}</p>
        {/* <button className="bg-blue-500 text-white py-2 px-4 rounded-full mt-4 hover:bg-blue-700"> */}
        <button className="block rounded-md bg-[#1369E9] px-5 py-2.5 mt-4 text-sm font-medium text-white transition hover:bg-[#2a4c80]">
          Apply
        </button>
      </div>
    );
  };
  const jobOpenings = [
    {
      position: "Frontend Engineer",
      vacancies: 2,
      location: "Remote",
    },
    {
      position: "Backend Developer",
      vacancies: 1,
      location: "Remote",
    },
    {
      position: "Marketing Manager",
      vacancies: 2,
      location: "Los Angeles, CA",
    },
  ];

  const team = [
    {
      avatar: "https://sproutsweb-assets.s3.amazonaws.com/images/niraj.jpeg",
      name: "Niraj Rai",
      title: "Founder and CEO",
      desc: "",
      linkedin: "https://www.linkedin.com/in/nirajcrai/",
      twitter: "javascript:void(0)",
      github: "javascript:void(0)",
    },
    {
      avatar: "https://sproutsweb-assets.s3.amazonaws.com/images/hrishikesh.jpeg",
      name: "Hrishikesh Sathawane",
      title: "Chief Revenue, and Marketing Officer (CRMO)",
      desc: "",
      linkedin: "https://www.linkedin.com/in/hmsathawane/",
      twitter: "javascript:void(0)",
      github: "javascript:void(0)",
    },
    {
      avatar: "https://sproutsweb-assets.s3.amazonaws.com/images/1659714799700.jpeg",
      name: "Joel Roberts ",
      title: "Customer Success and Growth",
      desc: "",
      linkedin: "https://www.linkedin.com/in/joel-roberts-15685623/",
      twitter: "javascript:void(0)",
      github: "javascript:void(0)",
    },
    {
      avatar: "https://sproutsweb-assets.s3.amazonaws.com/images/mohin.jpeg",
      name: "Mohinder Mahal",
      title: "Sales Leader",
      desc: "",
      linkedin: "https://www.linkedin.com/in/mmahal/",
      twitter: "javascript:void(0)",
      github: "javascript:void(0)",
    },
    {
      avatar: "images/gangina.png",
      name: "Gangina Prasad",
      title: "Technical Architect",
      desc: "",
      linkedin: "https://www.linkedin.com/in/gangina-prasad-919a314/",
      twitter: "javascript:void(0)",
      github: "javascript:void(0)",
    },
    // {
    //   avatar: "images/sudhir.jpg",
    //   name: "Sudhir Tickoo",
    //   title: "Customer Success Manager",
    //   desc: "",
    //   linkedin: null,
    //   twitter: "javascript:void(0)",
    //   github: "javascript:void(0)",
    // },
    // {
    //   avatar: "images/pankaj.jpg",
    //   name: "Pankaj Chauhan",
    //   title: "Software Engineer",
    //   desc: "",
    //   linkedin: null,
    //   twitter: "javascript:void(0)",
    //   github: "javascript:void(0)",
    // },
    // {
    //   avatar: "https://sproutsweb-assets.s3.amazonaws.com/images/jitender.jpeg",
    //   name: "Jitender Kumar",
    //   title: "Software Engineer",
    //   desc: "",
    //   linkedin:null,
    //   twitter: "javascript:void(0)",
    //   github: "javascript:void(0)",
    // },
    // {
    //   avatar: "https://sproutsweb-assets.s3.amazonaws.com/images/akriti.jpeg",
    //   name: "Akriti ghosh",
    //   title: "Software Engineer",
    //   desc: "",
    //   linkedin:null,
    //   twitter: "javascript:void(0)",
    //   github: "javascript:void(0)",
    // },
    // {
    //   avatar: "images/rohit.png",
    //   name: "Rohit Jangir",
    //   title: "Software Engineer",
    //   desc: "",
    //   linkedin:null,
    //   twitter: "javascript:void(0)",
    //   github: "javascript:void(0)",
    // },

    // {
    //   avatar: "https://sproutsweb-assets.s3.amazonaws.com/images/apoorva.jpeg",
    //   name: "Apoorva Sharma",
    //   title: "Customer Success Manager",
    //   desc: "",
    //   linkedin: null,
    //   twitter: "javascript:void(0)",
    //   github: "javascript:void(0)",
    // },
    // {
    //   avatar: "https://sproutsweb-assets.s3.amazonaws.com/images/team/Smita.jpg",
    //   name: "Smita Satapathy",
    //   title: "Customer Success Manager",
    //   desc: "",
    //   linkedin: null,
    //   twitter: "javascript:void(0)",
    //   github: "javascript:void(0)",
    // },
    // {
    //   avatar: "https://sproutsweb-assets.s3.amazonaws.com/images/team/Sourav.jpg",
    //   name: "Sourav Kumar Khan",
    //   title: "Software Engineer",
    //   desc: "",
    //   linkedin: null,
    //   twitter: "javascript:void(0)",
    //   github: "javascript:void(0)",
    // },
    // {
    //   avatar: "https://sproutsweb-assets.s3.amazonaws.com/images/team/Saikat_profile.jpg",
    //   name: "Saikat Bank",
    //   title: "Software Engineer",
    //   desc: "",
    //   linkedin: null,
    //   twitter: "javascript:void(0)",
    //   github: "javascript:void(0)",
    // },
    // {
    //   avatar: "https://sproutsweb-assets.s3.amazonaws.com/images/team/Image_20231106_094109_601.jpeg",
    //   name: "Sai Vinay",
    //   title: "Software Engineer",
    //   desc: "",
    //   linkedin: null,
    //   twitter: "javascript:void(0)",
    //   github: "javascript:void(0)",
    // },
  ];

  const company = [
    "images/intel.png",
    "images/ebay.png",
    "https://sproutsweb-assets.s3.amazonaws.com/images/yahoo.webp",
    "images/servicenow.png",
    "images/hp.png",
    "images/roblox.png",
    "images/cloudera.png",
    "images/cisco.png",
    "images/IITK.png",
    "images/scuc.png",
  ]

  const advisors = [
    {
      avatar: "https://sproutsweb-assets.s3.amazonaws.com/images/gurjeet.jpeg",
      name: "Gurjeet Singh",
      title: "CEO, Oma Fertility, Ayasdi",
      desc: "Ph D: Stanford Univ.",
      linkedin: null,
      twitter: "javascript:void(0)",
      github: "javascript:void(0)",
    },
    {
      avatar: "https://sproutsweb-assets.s3.amazonaws.com/images/anurag.jpeg",
      name: "Anurag Gupta",
      title: "CEO, Shoreline.io",
      desc: "Ex VP: AWS, Oracle ",
      linkedin: null,
      twitter: "javascript:void(0)",
      github: "javascript:void(0)",
    },
    {
      avatar: "https://sproutsweb-assets.s3.amazonaws.com/images/anupam.jpeg",
      name: "Anupam Datta ",
      title: "Founder & President, Truera",
      desc: "Ph D: Stanford Univ.",
      linkedin: null,
      twitter: "javascript:void(0)",
      github: "javascript:void(0)",
    },
    // {
    //   avatar: "images/Uday.jpg",
    //   name: "Uday Bellary",
    //   title: "Ex CFO for Public",
    //   desc: "Companies & Startups",
    //   linkedin: null,
    //   twitter: "javascript:void(0)",
    //   github: "javascript:void(0)",
    // },
    {
      avatar: "https://sproutsweb-assets.s3.amazonaws.com/images/avi+singh.jpeg",
      name: "Avi Singh M.",
      title: "AVP Technology , Cox Communications",
      desc: "Companies & Startups",
      linkedin: null,
      twitter: "javascript:void(0)",
      github: "javascript:void(0)",
    },
    {
      avatar: "https://sproutsweb-assets.s3.amazonaws.com/images/json.jpeg",
      name: "Jason Miller",
      title: "Talent and Operations",
      desc: "",
      linkedin: "https://www.linkedin.com/in/jason4linked/",
      twitter: "javascript:void(0)",
      github: "javascript:void(0)",
    },

    {
      avatar: "https://sproutsweb-assets.s3.amazonaws.com/images/brittsellin.jpeg",
      name: "Britt Sellin",
      title: "Advisor , SproutsAI",
      desc: "Companies & Startups",
      linkedin: null,
      twitter: "javascript:void(0)",
      github: "javascript:void(0)",
    },
    {
      avatar: "https://sproutsweb-assets.s3.amazonaws.com/images/sudhir.jpeg",
      name: "Sudhir Kumar Singh",
      title: "CTO @ Vimaan",
      desc: "Companies & Startups",
      linkedin: null,
      twitter: "javascript:void(0)",
      github: "javascript:void(0)",
    },
  ];

  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger once
  });

  return (
    <>

      <div ref={ref} className={`bg-[#F3F5F9] your-element py-20 ${inView ? "fade" : ""}`}>

        <div className="flex justify-center">
          <div className="max-w-screen-xl pb-4 px-4 sm:px-8">

            <div className="flex flex-col items-center">
              <span className="text-[#16182F]  text-[36px] not-italic font-bold">
                Our Vision
              </span>
              <span className="leading-[26px] mt-[20px] text-[#5F6989] text-[16px] not-italic font-normal">
                Free up knowledge workers from mundane tasks so they can focus on what
                high value creative work{" "}
              </span>
              <span className="leading-[26px]  text-[#5F6989] text-[16px] not-italic font-normal">
                and empower business to achieve their full potential.
              </span>
            </div>


            {/* Teams */}
            <section className="mt-20 flex flex-col justify-center">
              <h2 class="text-2xl font-semibold text-center text-gray-800 capitalize lg:text-3xl">
                Meet Our Team
              </h2>
              <div className="mt-12">
                <ul className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-20">
                  {team?.map((item, idx) => (
                    <li key={idx} className="bg-white shadow-md rounded-xl">
                      <div className="overflow-hidden">
                        <img
                          src={item.avatar}
                          alt={`Image of ${item.name}`}
                          className="object-cover w-full md:h-80 lg:h-80"
                        />
                      </div>
                      <div className="p-4">
                        <h4 className="text-lg text-gray-700 font-semibold">
                          {item.name}
                        </h4>
                        <p className="text-indigo-600">{item.title}</p>
                        <div className="mt-2 text-gray-600">
                          {/* Add team member description here */}
                        </div>
                        <div className="mt-4 flex space-x-4 text-gray-400">
                          {/* LinkedIn link */}
                          {item.linkedin != null &&
                            <a
                              href={item?.linkedin}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={Linkedin}
                                loading="lazy"
                                width={20}
                                height={20}
                                alt=""
                                className="vectors-wrapper-22 cursor-pointer "
                              />
                            </a>
                          }
                          {/* Add other social media links here */}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>


              </div>

            </section>
            {/* Advisors */}

            <div className="py-14">
              <div className="max-w-screen-xl mx-auto px-4 md:px-8">
                <div className="lg:max-w-screen-lg mx-auto text-center">
                  <h3 className="text-black text-3xl font-bold sm:text-4xl">
                    World class team
                  </h3>
                  <p className="text-gray-600 mt-3">
                    SproutsAI team brings decades of industry experience from Intel,
                    Yahoo, ServiceNow, Roblox, Cloudera, Stanford, Berkeley
                    University, Santa Clara University, IITs and top other companies.
                  </p>
                </div>
                <div className="mt-12 flex justify-center">

                  <ul className="inline-grid grid-cols-2 gap-x-15 gap-y-6 md:gap-x-16 lg:gap-x-28 md:grid-cols-3 lg:grid-cols-5">
                    {/* LOGO 1 */}
                    {company.map((item, idx) => (
                      <li key={idx}> {/* Adding a unique key for each list item */}
                        <img
                          src={item}
                          className="w-28 my-auto"
                          alt="Candidate Matching Image"
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <section className="mt-16 mb-16">
              <h2 class="text-2xl font-semibold text-center text-gray-800 capitalize lg:text-3xl">
                Meet Our Advisors
              </h2>
              <p className="text-gray-500 text-center mt-3">We are honored to have the support of a distinguished group of advisors.</p>
              <div className="max-w-screen-xl mx-auto px-4 md:px-8">
                <div className="max-w-xl"></div>
                <div className="mt-12">
                  <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-20">
                    {advisors?.map((item, idx) => (
                      <li key={idx} className="bg-white shadow-md rounded-xl">
                        <div className=" overflow-hidden">
                          <img
                            src={item.avatar}
                            alt={`Image of ${item.name}`}
                            loading="lazy"
                            className="object-cover w-full  md:h-80 lg:h-80"
                          />
                        </div>
                        <div className="p-4">
                          <h4 className="text-lg text-gray-700 font-semibold">
                            {item.name}
                          </h4>
                          <p className="text-indigo-600">{item.title}</p>
                          <div className="mt-2 text-gray-600">
                            {/* Add team member description here */}
                          </div>
                          <div className="mt-4 flex space-x-4 text-gray-400">
                            {/* Add social media links here */}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}











