import { Fragment, useState, useEffect, useRef } from "react";
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import { RiArrowDropDownLine } from "react-icons/ri";
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
} from "@heroicons/react/20/solid";
import { useLocation, useNavigate } from "react-router-dom";

export default function Header() {
  const products = [
    {
      name: "Autonomous Agent Platform",
      description: "Get a better understanding of your traffic",
      href: "/AutonomousAgentPlatform",
      icon: ChartPieIcon,
    },
    {
      name: "No Code Builder",
      description: "Speak directly to your customers",
      href: "/nocodebuilder",
      icon: CursorArrowRaysIcon,
    },
    {
      name: "Integrations",
      description: "Your customers’ data will be safe and secure",
      href: "/integrations",
      icon: FingerPrintIcon,
    },
  ];
  const solutions = [
    { name: " HR Solution", href: "/hrsolution", icon: PlayCircleIcon },
    {
      name: "Sales and Marketing",
      href: "/salesandmarketing",
      icon: PhoneIcon,
    },
    { name: "Industry Solution", href: "/industries", icon: PhoneIcon },
    {
      name: "Developer Relations",
      href: "/developerrelations",
      icon: PhoneIcon,
    },
  ];
  const company = [
    { name: "About Us", href: "/company", icon: PlayCircleIcon },
    {
      name: "Careers",
      href: "https://beta.sproutsai.com/job-post/SproutsAI.dev",
      icon: PhoneIcon,
    },
    { name: "  Contact Us", href: "/contact", icon: PhoneIcon },
  ];
  const resources = [
    { name: " Blogs", href: " blog", icon: PlayCircleIcon },
    { name: "Webinars", href: "/webinars", icon: PhoneIcon },
    { name: "ROI Calculator", href: "/roi-calculator", icon: PhoneIcon },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const loc = useLocation();
  console.log(loc.pathname);

  const NavItem = ({ title, items }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          setIsOpen(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    return (
      <li className="relative" ref={dropdownRef}>
        <button
          className={`text-black-500 transition flex items-center text-[18px] hover:text-blue-600 hover:text-black-500/75 ${
            isOpen ? "text-blue-600" : ""
          }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          {title}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            className={`relative top-[1px] ml-1 h-3 w-3 transition duration-200 ${
              isOpen ? "rotate-180" : ""
            }`}
            aria-hidden="true"
          >
            <path d="m6 9 6 6 6-6"></path>
          </svg>
          {/* <RiArrowDropDownLine /> */}
        </button>
        {isOpen && (
          <ul className="absolute rounded-md bg-[#FFF] p-[10px] shadow-lg ring-1 ring-black ring-opacity-5 z-10 text-gray-700 w-60 mt-0.5 border">
            {items.map((item, idx) => (
              <li key={idx}>
                <a
                  className={`rounded-t hover:bg-[#F3F5F9] py-2 px-4 block ${
                    loc.pathname === item.href ? "bg-[#F3F5F9]" : ""
                  }`}
                  href={item.href}
                >
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        )}
      </li>
    );
  };

  const Navigation = () => {
    return (
      <nav aria-label="Global" className="hidden md:block">
        <ul className="flex items-center ml-10 gap-12 text-sm">
          <NavItem title="Products" items={products} />
          <NavItem title="Solutions" items={solutions} />
          <NavItem title="Resources" items={resources} />
          <NavItem title="Company" items={company} />
          <li>
            <a
              className={`text-black-500 transition text-[18px] hover:text-blue-600 hover:text-black-500/75 ${
                loc.pathname === "/pricing" ? "text-blue-600" : ""
              }`}
              href="/pricing"
            >
              Pricing
            </a>
          </li>
        </ul>
      </nav>
    );
  };

  return (
    <div>
      <header className="bg-[#F3F5F9]">
        <nav
          className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img className="h-8 w-auto" src="images/logo.svg" alt="" />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <Popover.Group className="hidden lg:flex lg:gap-x-12">
            <Navigation />
          </Popover.Group>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a
              className="bg-[#1369E9] border border-solid text-[#fff] inline-flex items-center  px-5 py-2.5
              
                 rounded-lg    justify-center  text-[18px] not-italic font-normal hover:border-[#145ecc] hover:bg-[#145ecc] hover:shadow-jobPostButtonShadow focus:border-[#4287ED] focus:bg-[#4287ED] focus:shadow-buttonFocusShadow  focus:outline-none"
              href="https://beta.sproutsai.com/login"
              target="_blank"
            >
              Login
            </a>

            <a
              className="hidden  rounded-md bg-black-100 pl-5 py-2.5 text-sm font-medium text-[#1369E9] transition hover:text-[#1369E9]/75 md:block"
              href="https://beta.sproutsai.com/signup"
              target="_blank"
            >
              Sign Up
            </a>
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img className="h-8 w-auto" src="images/logo.svg" alt="" />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <Disclosure as="div" className="-mx-3">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                          Products
                          <ChevronDownIcon
                            className={classNames(
                              open ? "rotate-180" : "",
                              "h-5 w-5 flex-none"
                            )}
                            aria-hidden="true"
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="mt-2 space-y-2 z-10">
                          {[...products].map((item) => (
                            <Disclosure.Button
                              key={item.name}
                              as="a"
                              href={item.href}
                              className="block rounded-lg py-2 z-10 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                            >
                              {item.name}
                            </Disclosure.Button>
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>

                  <Disclosure as="div" className="-mx-3">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                          Solutions
                          <ChevronDownIcon
                            className={classNames(
                              open ? "rotate-180" : "",
                              "h-5 w-5 flex-none"
                            )}
                            aria-hidden="true"
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="mt-2 space-y-2">
                          {[...solutions].map((item) => (
                            <Disclosure.Button
                              key={item.name}
                              as="a"
                              href={item.href}
                              className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                            >
                              {item.name}
                            </Disclosure.Button>
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                  <a
                    href="/pricing"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Pricing
                  </a>
                  <Disclosure as="div" className="-mx-3">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                          Resources
                          <ChevronDownIcon
                            className={classNames(
                              open ? "rotate-180" : "",
                              "h-5 w-5 flex-none"
                            )}
                            aria-hidden="true"
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="mt-2 space-y-2">
                          {[...resources].map((item) => (
                            <Disclosure.Button
                              key={item.name}
                              as="a"
                              href={item.href}
                              className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                            >
                              {item.name}
                            </Disclosure.Button>
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                  <Disclosure as="div" className="-mx-3">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                          Company
                          <ChevronDownIcon
                            className={classNames(
                              open ? "rotate-180" : "",
                              "h-5 w-5 flex-none"
                            )}
                            aria-hidden="true"
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="mt-2 space-y-2">
                          {[...company].map((item) => (
                            <Disclosure.Button
                              key={item.name}
                              as="a"
                              href={item.href}
                              className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                            >
                              {item.name}
                            </Disclosure.Button>
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </div>
                <div className="py-6">
                  <a
                    href="https://beta.sproutsai.com/login"
                    target="_blank"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </a>
                  <a
                    href="https://beta.sproutsai.com/signup"
                    target="_blank"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Sign Up
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
    </div>
  );
}
