// src/components/JobPost.js
import React from 'react';


const SoftwareDeveloper = () => {
    const jobPostingSchema = {
        "@context": "http://schema.org",
        "@type": "JobPosting",
        "title": "Software Engineer",
        "description": "Join our team as a Software Engineer. You will be responsible for developing high-quality software solutions and collaborating with cross-functional teams.",
        "identifier": {
            "@type": "PropertyValue",
            "name": "SproutsAI",
            "value": "123456"
        },
        "datePosted": "2024-06-18",
        "validThrough": "2024-12-31",
        "employmentType": "FULL_TIME",
        "hiringOrganization": {
            "@type": "Organization",
            "name": "SproutsAI",
            "sameAs": "https://www.sproutsai.com/career/123456"
        },
        "jobLocation": {
            "@type": "Place",
            "address": {
                "@type": "PostalAddress",
                "streetAddress": "123 Main Street",
                "addressLocality": "Cityville",
                "addressRegion": "CA",
                "postalCode": "12345",
                "addressCountry": "US"
            }
        },
        "baseSalary": {
            "@type": "MonetaryAmount",
            "currency": "USD",
            "value": {
                "@type": "QuantitativeValue",
                "value": 100000,
                "unitText": "YEAR"
            }
        },
        "jobLocationType": "TELECOMMUTE",
        "applicantLocationRequirements": {
            "@type": "Country",
            "name": "US"
        },
        "experienceRequirements": "2+ years of software development experience.",
        "educationRequirements": "Bachelor's degree in Computer Science or related field.",
        "qualifications": "Proficiency in JavaScript, React, and Node.js.",
        "skills": "JavaScript, React, Node.js, problem-solving, teamwork.",
        "industry": "Software Development",
        "incentives": "Stock options, performance bonus.",
        "responsibilities": "Develop software solutions, collaborate with teams, write clean code.",
        "workHours": "40 hours per week.",
        "jobBenefits": "Health insurance, 401(k), paid time off."
    };

    return (
        <div className="max-w-3xl mx-auto p-6 py-10 bg-white shadow-md rounded-lg">
            <h1 className="text-2xl font-bold text-blue-600 mb-4">Software Engineer</h1>
            <p className="mb-4">Join our team as a Software Engineer. You will be responsible for developing high-quality software solutions and collaborating with cross-functional teams.</p>
            <div className="mb-4">
                <p><strong>Hiring Organization:</strong> SproutsAI</p>
                <p><strong>Location:</strong> Cityville, CA</p>
                <p><strong>Salary:</strong> $100,000 per year</p>
                <p><strong>Employment Type:</strong> Full-time, Telecommute</p>
            </div>
            <div className="mb-4">
                <h2 className="text-xl font-semibold mb-2">Requirements</h2>
                <ul className="list-disc list-inside space-y-2">
                    <li>2+ years of software development experience</li>
                    <li>Bachelor's degree in Computer Science or related field</li>
                    <li>Proficiency in JavaScript, React, and Node.js</li>
                </ul>
            </div>
            <div className="mb-4">
                <h2 className="text-xl font-semibold mb-2">Responsibilities</h2>
                <ul className="list-disc list-inside space-y-2">
                    <li>Develop software solutions</li>
                    <li>Collaborate with cross-functional teams</li>
                    <li>Write clean and maintainable code</li>
                </ul>
            </div>
            <div className="mb-4">
                <h2 className="text-xl font-semibold mb-2">Skills</h2>
                <ul className="list-disc list-inside space-y-2">
                    <li>JavaScript</li>
                    <li>React</li>
                    <li>Node.js</li>
                    <li>Problem-solving</li>
                    <li>Teamwork</li>
                </ul>
            </div>
            <div className="mb-4">
                <h2 className="text-xl font-semibold mb-2">Benefits</h2>
                <ul className="list-disc list-inside space-y-2">
                    <li>Health insurance</li>
                    <li>401(k)</li>
                    <li>Paid time off</li>
                    <li>Stock options</li>
                    <li>Performance bonus</li>
                </ul>
            </div>
            <a href="https://www.sproutsai.com/career/123456" className="inline-block bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300">Apply Now</a>
            <script type="application/ld+json">
                {JSON.stringify(jobPostingSchema)}
            </script>
        </div>
    );
};

export default SoftwareDeveloper;
