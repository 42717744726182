import React from 'react'

function Usage() {
    return (
        <div className='bg-[#F3F5F9] pb-20'>
            <div className={`flex justify-center bg-[#F3F5F9]  `}>

                <div className="flex flex-col justify-center items-center lg:flex-col     max-w-screen-xl   px-4 sm:px-6 lg:px-8 py-[40px] lg:py-[70px]">
                    <h1 className="text-gray-800 font-bold text-4xl md:text-5xl">
                        Zoom usage
                    </h1>
                    <div class="w-full p-4 mt-[40px] bg-white ">
                        <p class="font-medium text-indigo-500 text-md">
                        </p>
                        <p class="mb-2 text-xl font-medium text-gray-800 dark:text-white">
                            Quick start guide
                        </p>
                        <p class="font-light text-gray-700 dark:text-gray-500 text-md">
                            SproutsAI leverages the power of AI to enhance your Zoom assessments and meetings.
                            Our AI-driven platform offers a range of intelligent features designed to streamline your virtual interactions:
                        </p>


                        <div >

                            <div class="mt-4 mb-8">
                                <p class=" text-xl font-medium text-gray-800 dark:text-white">
                                    Pre-requisites
                                </p>

                                <p class="mt-2 mb-4">
                                    To get started, users only need to open the provided page and grant access and permissions for the following:
                                </p>

                                <ul class="list-decimal list-inside mt-2">
                                    <li class="mb-1">Microphone, speaker, and camera devices</li>
                                    <li class="mb-1">An active internet connection</li>
                                </ul>
                            </div>

                            <p class="mb-2 text-xl font-medium text-gray-800 dark:text-white">
                                Adding the app
                            </p>
                            <p class="font-semibold mb-2">
                                For adding the Zoom SDK, the user needs to follow the given process
                            </p>


                            <ol class="list-decimal pl-6 pr-4 py-4 text-md text-gray-700 dark:text-gray-500">
                                <li class="mb-6">
                                    <p class="mb-3">Step 1:</p>
                                    <p>Create your candidate account on SproutsAI. This involves signing up with your personal information, such as your name, email address, and password. Your candidate account will serve as your portal to job opportunities and assessments.</p>
                                </li>
                                <li class="mb-6">
                                    <p class="mb-3">Step 2:</p>
                                    <p>Apply for a job that aligns with your interests and qualifications. This step often involves submitting your resume, cover letter, and any other requested documents. Make sure to tailor your application to the specific job requirements.</p>
                                </li>
                                <li class="mb-6">
                                    <p class="mb-3">Step 3:</p>
                                    <p>Wait for the recruiter to accept your application and reach out to you. This may take some time as the recruiter reviews applications from multiple candidates. Keep an eye on your email and notifications for updates.</p>
                                </li>
                                <li class="mb-6">
                                    <p class="mb-3">Step 4:</p>
                                    <p>If the job you applied for includes an assessment as part of the selection process, the recruiter will send you an invitation to access the assessment. This invitation will contain details about the assessment's nature, duration, and any specific instructions.</p>
                                </li>
                                <li class="mb-6">
                                    <p class="mb-3">Step 5:</p>
                                    <p>Explore the 'Assessment' tab in the sidebar of your SproutsAI account. Once you've received the assessment invitation, you can easily locate the 'Assessment' tab in the user interface. Click on it to access the Assessments dashboard.</p>
                                </li>
                                <li class="mb-6">
                                    <p class="mb-3">Step 6:</p>
                                    <p>Initiate the assessment by clicking on the relevant option and grant access for your microphone and camera if required. The assessment will guide you through the tasks or questions, and your microphone and camera access may be necessary for real-time interactions or proctoring purposes.</p>
                                </li>
                            </ol>

                            <p class="font-semibold mt-2">
                                The Zoom SDK will create a meeting and your answers will be recorded.
                            </p>

                        </div>
                        <div>
                            <div class="mt-8">




                            </div>

                            <div class="mt-8">
                                <h3 class="text-md font-semibold mt-4">Actions</h3>
                                <p class="mt-2">During the assessment, the following features and actions are performed:</p>

                                <div class="mt-4 ml-4">
                                    <h5 class="text-md font-semibold">Access Microphone</h5>
                                    <p class="mt-2">This permission allows us to use your computer's built-in or attached microphone to record your answers to interview questions.</p>
                                </div>

                                <div class="mt-4 ml-4">
                                    <h5 class="text-md font-semibold">Access Camera</h5>
                                    <p class="mt-2">This permission allows us to use your computer's built-in or attached camera to record your visual and gestural responses to interview questions.</p>
                                </div>

                                <div class="mt-4 ml-4">
                                    <h5 class="text-md font-semibold">Screen Recording</h5>
                                    <p class="mt-2">This permission enables us to access and record your monitor's screen to capture your responses for the recruiter and to detect any plagiarism, falsification, or infringement.</p>
                                </div>
                            </div>


                            <div class="mt-8">
                                <p class=" text-xl font-medium text-gray-800 dark:text-white">
                                    Usage
                                </p>

                                <div class="ml-4">
                                    <div class="mt-4">
                                        <p class=" text-md font-medium text-blue-800 dark:text-white">
                                            Seamless Interviews
                                        </p>

                                        <p class="mt-2">
                                            SproutsAI leverages the Zoom SDK to enable recruiters to schedule and conduct interviews effortlessly.
                                        </p>
                                    </div>
                                    <div class="mt-4">
                                        <p class=" text-md font-medium text-blue-800 dark:text-white">
                                            Recording Interviews
                                        </p>

                                        <p class="mt-2">
                                            The Zoom SDK allows us to record candidate interviews within SproutsAI, providing valuable insights for evaluation.
                                        </p>
                                    </div>
                                    <div class="mt-4">
                                        <p class=" text-md font-medium text-blue-800 dark:text-white">
                                            Real-time Interaction
                                        </p>

                                        <p class="mt-2">
                                            SproutsAI, powered by Zoom SDK, facilitates real-time interactions between candidates and recruiters, including video, audio, and chat functionalities.
                                        </p>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div class="mt-8">
                            <p class=" text-xl font-medium text-gray-800 dark:text-white">
                                Removing the App
                            </p>

                            <ol class="list-decimal  mt-4 ml-4">
                                <li class="mb-2">
                                    <p class="mt-2">Login to your Zoom account and navigate to the Zoom Marketplace.</p>
                                </li>
                                <li class="mb-2">
                                    <p class="mt-2">Click on <span class="font-semibold">Manage</span> &gt; <span class="font-semibold">Installed Apps</span> or search for the <span class="font-semibold">SproutsAI app</span>.</p>
                                </li>
                                <li class="mb-2">
                                    <p class="mt-2">Click <span class="font-semibold">Uninstall</span> next to <span class="font-semibold">SproutsAI</span>.</p>
                                </li>
                                <li class="mb-2">
                                    <p class="mt-2">Confirm the dialogue by clicking <span class="font-semibold">Uninstall</span>.</p>
                                </li>
                            </ol>

                            <div class="mt-4">

                            </div>
                        </div>

                        <div >
                            <p class=" text-xl font-medium text-gray-800 dark:text-white">
                                Note
                            </p>
                            <p>
                                The Zoom SDK and all it's embedded features are limited to the particular page only. As soon as either the user closes the page or the assessment ends, the meeting and all its features are automatically closed and shut down.
                            </p>
                            ‍‍
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Usage