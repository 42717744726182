import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useLocation } from 'react-router-dom';



ChartJS.register(ArcElement, Tooltip, Legend);
function RoiCalculator() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    recruiter: "",
    volume: "",
    salary: "",
    avgTime: "",
    outSideAgencies: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const loc = useLocation()
  console.log(loc?.search)

  const handleSubmit = () => {


    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      firstname: formData.firstName,
      lastname: formData.lastName,
      name: formData.firstName + " " + formData.lastName,
      email: formData.email,
      message: formData.message,
      phone: formData.phone,
      forReq: loc?.search === "?demo" ? "demo" : loc?.search === "?webinar" ? "webinar" : "contact",
      subject: "Contact Request - SproutsAI",
      to: "contacts@sproutsai.com",
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://api.sproutsai.com/controls/send-demo-mail", requestOptions)
      .then(response => response.text())
      .then(result => {
        setIsModalOpen(true);
        // Optionally, you can reset the form after a successful submission
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          message: "",
          phone: "",
        });
      })
      .catch(error => console.error('Error:', error));
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  const [calValue, setCalValue] = useState(0)

  const [value, setValue] = useState(0);
  const chartRef = useRef();



  const options = {
    cutout: '80%', // Adjust the size of the center hole
    plugins: {
      tooltip: {
        enabled: false, // Disable tooltip if not needed
      },
      legend: {
        display: false, // Hide legend if not needed
      },
    },
  };

  const centerText = value + '%';


  const [saveDays, setSaveDays] = useState(0)
  const [totalsaving, settotalSaving] = useState(0)
  const [perhiresaving, setPerhireSaving] = useState(0)


  const calculate = () => {
    setCalValue(((159 * formData?.volume * formData?.salary) - (62.1 * formData?.volume * formData?.salary - 600 * formData?.volume)) / (159 * formData?.volume * formData?.salary))
    setSaveDays(62.1 * formData?.volume / 24)
    settotalSaving(62.1 * formData?.volume * formData?.salary - 600 * formData?.volume)
    setPerhireSaving(62.1 * formData?.salary)

    // =((E17*B4)-B25)/(E17*B4)
    // setCalValue(62.1*formData?.volume* formData?.salary )
    // 


  }




  const memoizedChart = useMemo(
    () => (
      <div className='flex flex-col justify-center items-center'>

        <Doughnut
          data={{
            labels: [''],
            datasets: [
              {
                data: [calValue * 100, 100 - calValue * 100],
                backgroundColor: ['#1369E9', 'rgba(54, 162, 235, 0.2)'],
                borderColor: ['#1369E9', 'rgba(54, 162, 235, 0.2)'],
                borderWidth: 1,
              },
            ],
          }}
          options={options}
          ref={chartRef}
          width={150}
          height={150}
          plugins={[
            {
              beforeDraw: (chart) => {
                const ctx = chart.ctx;
                const canvas = chart.canvas;
                const width = chart.width;
                const height = chart.height;



                ctx.restore();

              }
            },
          ]}
        />
      </div>
    ),
    [options, calValue]
  );

  const memoizedChart2 = useMemo(
    () => (
      <div className='flex flex-col justify-center items-center'>

        <Doughnut
          data={{
            labels: [''],
            datasets: [
              {
                data: [saveDays, 365 - saveDays > 0 ? 365 - saveDays : 0],
                backgroundColor: ['#1369E9', 'rgba(54, 162, 235, 0.2)'],
                borderColor: ['#1369E9', 'rgba(54, 162, 235, 0.2)'],
                borderWidth: 1,
              },
            ],
          }}
          options={options}
          ref={chartRef}
          width={150}
          height={150}
          plugins={[
            {
              beforeDraw: (chart) => {
                const ctx = chart.ctx;
                const canvas = chart.canvas;
                const width = chart.width;
                const height = chart.height;



                ctx.restore();

              }
            },
          ]}
        />

      </div>
    ),
    [options, calValue]
  );

  const memoizedChart3 = useMemo(
    () => (
      <div className='flex flex-col justify-center items-center'>

        <Doughnut
          data={{
            labels: [''],
            datasets: [
              {
                data: [perhiresaving, 5000 - perhiresaving > 0 ? 5000 - perhiresaving : 0],
                backgroundColor: ['#1369E9', 'rgba(54, 162, 235, 0.2)'],
                borderColor: ['#1369E9', 'rgba(54, 162, 235, 0.2)'],
                borderWidth: 1,
              },
            ],
          }}
          options={options}
          ref={chartRef}
          width={150}
          height={150}
          plugins={[
            {
              beforeDraw: (chart) => {
                const ctx = chart.ctx;
                const canvas = chart.canvas;
                const width = chart.width;
                const height = chart.height;



                ctx.restore();

              }
            },
          ]}
        />

      </div>
    ),
    [options, calValue]
  );


  const [showROIInfo, setShowROIInfo] = useState(false);

  return (
    <section class=" bg-[#F3F5F9] ">
      <div className={`flex justify-center bg-[#F3F5F9] `}>
        <div className="flex flex-col justify-center items-center lg:flex-row     max-w-screen-xl   px-4 sm:px-6 lg:px-8 py-[40px] lg:py-[70px]">
          <div class="container px-6 py-12 mx-auto">
            <div className='ml-8 mt-11 text-center'>
              <h1 class="text-2xl font-semibold text-gray-800 capitalize lg:text-3xl mb-[30px] ">
                How much can you save with SproutsAI?
              </h1>
              <span className='text-[16px] text-gray-600 font-semibold mt-[30px]'>Find out how much time and money you could potentially save on hiring with SproutsAi Platform</span>
            </div>

            <div class="grid grid-cols-1 gap-12 mt-10 lg:grid-cols-2">


              <div class="p-4 py-6 rounded-lg bg-[#FFF] shadow-CardsShadow md:p-8">
                <form

                  onSubmit={e => {
                    e.preventDefault()
                    calculate()
                  }
                  }>
                  <div className="-mx-2 md:items-center md:flex">
                    <div className="flex-1 px-2">
                      <label className="block mb-2 text-sm text-gray-600 ">
                        First Name *
                      </label>
                      <input
                        required
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        placeholder="John"
                        className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg    focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                      />
                    </div>

                    <div className="flex-1 px-2 mt-4 md:mt-0">
                      <label className="block mb-2 text-sm text-gray-600 ">
                        Last Name
                      </label>
                      <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        placeholder="Doe"
                        className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg  focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                      />
                    </div>
                  </div>

                  <div className="mt-4">
                    <label className="block mb-2 text-sm text-gray-600 ">
                      Company
                    </label>
                    <input
                      required
                      type="text"
                      name="company"
                      placeholder='SproutsAI'
                      value={formData.company}
                      onChange={handleChange}
                      className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg  focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                  </div>

                  <div className="mt-4">
                    <label className="block mb-2 text-sm text-gray-600 ">
                      Business email address *
                    </label>
                    <input
                      required
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="johndoe@example.com"
                      className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg  focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                  </div>
                  <div className="mt-4">
                    <label className="block font-semibold text-gray-800 ">
                      The number of recruiters on staff *
                    </label>
                    <span className='block mb-2 text-sm text-gray-600'>Please enter the number of recruiters you currently have on your team.</span>
                    <input
                      required
                      type="number"
                      min={1}
                      name="recruiter"
                      value={formData.recruiter}
                      onChange={handleChange}
                      placeholder="# 10"
                      className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg  focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                  </div>
                  <div className="mt-4">
                    <label className="block font-semibold text-gray-800 ">
                      Annual Hiring Volume *
                    </label>
                    <span className='block mb-2 text-sm text-gray-600'>Please enter the total number of positions filled per year for the company or organization</span>

                    <input
                      required
                      type="text"
                      min={1}
                      name="volume"
                      value={formData.volume.toLocaleString()} // Format for display
                      onChange={(e) => {
                        // Remove commas and other non-numeric characters
                        const rawValue = e.target.value.replace(/[^0-9.]/g, '');
                        // Update formData.salary with the raw numeric value
                        if (rawValue) {
                          setFormData({ ...formData, volume: parseFloat(rawValue) });
                        }
                        else {
                          setFormData({ ...formData, volume: "" });
                        }

                      }}
                      placeholder="# 100"
                      className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg  focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                  </div>
                  <div className="mt-4">
                    <label className="block font-semibold text-gray-800 mb-2 ">
                      Per hour labor cost (USD) *
                    </label>
                    <input
                      required
                      min={1}
                      type="text"
                      placeholder='$ 100'
                      name="salary"
                      value={formData.salary.toLocaleString()} // Format for display
                      onChange={(e) => {
                        // Remove commas and other non-numeric characters
                        const rawValue = e.target.value.replace(/[^0-9.]/g, '');
                        // Update formData.salary with the raw numeric value
                        if (rawValue) {
                          setFormData({ ...formData, salary: parseFloat(rawValue) });
                        }
                        else {
                          setFormData({ ...formData, salary: "" });
                        }

                      }}

                      className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg  focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />

                  </div>
                  <div className="mt-4">
                    <label className="block font-semibold text-gray-800 ">
                      Average time to fill a position (days) *
                    </label>
                    <span className='block mb-2 text-sm text-gray-600'>Average time it takes to fill a position (between 0 and 365)</span>

                    <input
                      required
                      type="number"
                      min={1}
                      max={365}
                      name="avgTime"
                      value={formData.avgTime}
                      onChange={handleChange}
                      placeholder="# 60"
                      className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg  focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                  </div>
                  <div className="mt-4">
                    <label className="block font-semibold text-gray-800 ">
                      Percentage of jobs filled by outside agencies *
                    </label>
                    <input
                      required
                      type="number"
                      min={1}
                      max={100}
                      name="outSideAgencies"
                      value={formData.outSideAgencies}
                      onChange={handleChange}
                      placeholder="# 80%"
                      className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg  focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                  </div>
                  <button type='submit' className="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-lg hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50">
                    Calculate
                  </button>
                </form>
              </div>
              {/* <span>Based on {formData?.recruiter} seats of SproutsAI Recruit - Enterprise</span> */}



              <div className=''>
                <div className='flex justify-center'>
                  <span className='text-[16px] text-gray-600 font-semibold mt-[10px]'>Based on {formData?.recruiter} seats of SproutsAI Recruit - Enterprise</span>
                </div>
                <div className='grid grid-cols-1 gap-12 md:grid-cols-3 mt-[20px]'>

                  <div className='col-span-1 flex justify-center items-center flex-col'>
                    <div className=' relative flex items-center justify-center'>
                      <span className='absolute text-gray-600 text-[22px] font-bold'>{Math.round(calValue * 100) + " %"}
                      </span>
                      {memoizedChart}
                    </div>
                    <span className='text-[12px] font-medium mt-[10px] text-gray-600'>Total saving percent </span>
                  </div>

                  <div className='col-span-1 flex justify-center items-center flex-col'>
                    <div className='relative flex items-center justify-center'>
                      <span className='absolute text-[22px] font-bold text-gray-600'>{Math.round(saveDays) + " days"}</span>
                      {memoizedChart2}
                    </div>
                    <span className='text-[12px] font-semibold mt-[10px] text-gray-600'>Reduction in time to hire</span>
                  </div>

                  <div className='col-span-1 flex justify-center items-center flex-col'>
                    <div className='relative flex items-center justify-center'>
                      <span className='absolute text-[22px] font-bold text-gray-600'>
                        {/* {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD'
                        }).format(perhiresaving).split(".")[0]} */}

                        {"$ " + (Math.floor(perhiresaving)).toLocaleString()}
                      </span>
                      {memoizedChart3}
                    </div>
                    <span className='text-[12px] font-semibold mt-[10px] text-gray-600'>Saving cost per hire </span>
                  </div>
                </div>

                <div className='flex flex-col justify-center items-center mt-[40px] relative'>
                  <span className='text-[22px] font-bold not-italic text-gray-600'>
                    {"$ " + Math.floor(totalsaving).toLocaleString()}
                  </span>

                  <span className='mt-[20px] flex items-center  text-[22px] font-bold not-italic text-gray-600'>{"Total Expected Net Annual Savings"}
                    <svg
                      onMouseEnter={() => setShowROIInfo(true)}
                      onMouseLeave={() => setShowROIInfo(false)}
                      xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="22" height="22" viewBox="0 0 512 512" className='ml-[10px] cursor-pointer'>
                      <path fill="#25B7D3" d="M504.1,256C504.1,119,393,7.9,256,7.9C119,7.9,7.9,119,7.9,256C7.9,393,119,504.1,256,504.1C393,504.1,504.1,393,504.1,256z"></path><path fill="#FFF" d="M323.2 367.5c-1.4-2-4-2.8-6.3-1.7-24.6 11.6-52.5 23.9-58 25-.1-.1-.4-.3-.6-.7-.7-1-1.1-2.3-1.1-4 0-13.9 10.5-56.2 31.2-125.7 17.5-58.4 19.5-70.5 19.5-74.5 0-6.2-2.4-11.4-6.9-15.1-4.3-3.5-10.2-5.3-17.7-5.3-12.5 0-26.9 4.7-44.1 14.5-16.7 9.4-35.4 25.4-55.4 47.5-1.6 1.7-1.7 4.3-.4 6.2 1.3 1.9 3.8 2.6 6 1.8 7-2.9 42.4-17.4 47.6-20.6 4.2-2.6 7.9-4 10.9-4 .1 0 .2 0 .3 0 0 .2.1.5.1.9 0 3-.6 6.7-1.9 10.7-30.1 97.6-44.8 157.5-44.8 183 0 9 2.5 16.2 7.4 21.5 5 5.4 11.8 8.1 20.1 8.1 8.9 0 19.7-3.7 33.1-11.4 12.9-7.4 32.7-23.7 60.4-49.7C324.3 372.2 324.6 369.5 323.2 367.5zM322.2 84.6c-4.9-5-11.2-7.6-18.7-7.6-9.3 0-17.5 3.7-24.2 11-6.6 7.2-9.9 15.9-9.9 26.1 0 8 2.5 14.7 7.3 19.8 4.9 5.2 11.1 7.8 18.5 7.8 9 0 17-3.9 24-11.6 6.9-7.6 10.4-16.4 10.4-26.4C329.6 96 327.1 89.6 322.2 84.6z"></path>
                    </svg>
                  </span>
                  {showROIInfo && <ROIInfo
                    onMouseEnter={() => setShowROIInfo(true)}
                    onMouseLeave={() => setShowROIInfo(false)}
                  />}
                </div>

                <div>

                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default RoiCalculator


const ROIInfo = ({ onMouseEnter, onMouseLeave }) => {
  return (
    <div className="max-w-2xl mx-auto p-4 absolute top-[100px] bg-black rounded-[12px]"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <h2 className="text-xl font-semibold mb-4 text-white">How we calculate ROI</h2>
      <p className="mb-4 text-white">
        These savings estimates are based on industry experience and surveys of SproutsAi customers. We make some
        assumptions around industry costs and average usage of tools.
      </p>
      <p className="mb-4 text-white">
        Across the calculations, we are using four different scenarios to calculate the expected overall return on
        investment:
      </p>
      <ol className="list-decimal pl-6 mb-4 text-white">
        <li className="mb-2">Recruiter efficiency improvement</li>
        <li className="mb-2">Hiring Team productivity savings</li>
        <li className="mb-2">Agency fees and other tools cost reduction</li>
        <li className="mb-2">Opportunity cost of vacant positions</li>
      </ol>
    </div>
  );
};