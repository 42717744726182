// // import React, { useEffect, useState } from 'react';
// // import { useParams } from 'react-router-dom';
// // import axios from 'axios';

// // const JobDetails = () => {
// //     const { id } = useParams();
// //     const [job, setJob] = useState(null);

// //     useEffect(() => {
// //         const fetchJob = async () => {
// //             try {
// //                 const response = await axios.get(`http://localhost:3000/job/${id}`);
// //                 setJob(response.data);
// //             } catch (err) {
// //                 console.error('Error fetching job details:', err);
// //             }
// //         };
// //         fetchJob();
// //     }, [id]);

// //     if (!job) {
// //         return <div>Loading...</div>;
// //     }

// //     const jobPostingSchema = {
// //         '@context': 'http://schema.org',
// //         '@type': 'JobPosting',
// //         'title': job.title,
// //         'description': job.description,
// //         'identifier': {
// //             '@type': 'PropertyValue',
// //             'name': job.identifier.name,
// //             'value': job.identifier.value
// //         },
// //         'datePosted': job.datePosted,
// //         'validThrough': job.validThrough,
// //         'employmentType': job.employmentType,
// //         'hiringOrganization': {
// //             '@type': 'Organization',
// //             'name': job.hiringOrganization.name,
// //             'sameAs': job.hiringOrganization.sameAs
// //         },
// //         'jobLocation': {
// //             '@type': 'Place',
// //             'address': {
// //                 '@type': 'PostalAddress',
// //                 'streetAddress': job.jobLocation.streetAddress,
// //                 'addressLocality': job.jobLocation.addressLocality,
// //                 'addressRegion': job.jobLocation.addressRegion,
// //                 'postalCode': job.jobLocation.postalCode,
// //                 'addressCountry': job.jobLocation.addressCountry
// //             }
// //         },
// //         'baseSalary': {
// //             '@type': 'MonetaryAmount',
// //             'currency': job.baseSalary.currency,
// //             'value': {
// //                 '@type': 'QuantitativeValue',
// //                 'value': job.baseSalary.value.value,
// //                 'unitText': job.baseSalary.value.unitText
// //             }
// //         },
// //         'jobLocationType': job.jobLocationType,
// //         'applicantLocationRequirements': {
// //             '@type': 'Country',
// //             'name': job.applicantLocationRequirements.name
// //         },
// //         'experienceRequirements': job.experienceRequirements,
// //         'educationRequirements': job.educationRequirements,
// //         'qualifications': job.qualifications,
// //         'skills': job.skills,
// //         'industry': job.industry,
// //         'incentives': job.incentives,
// //         'responsibilities': job.responsibilities,
// //         'workHours': job.workHours,
// //         'jobBenefits': job.jobBenefits
// //     };

// //     return (
// //         <div className="py-8 px-2 bg-white">
// //             <div className="max-w-4xl mx-auto p-8 bg-gray-100 border-gray-100 shadow-md rounded-lg">
// //                 <h2 className="text-3xl font-bold mb-8 text-gray-800">{job.title}</h2>
// //                 <p><strong>ID:</strong> {job.identifier.value}</p>
// //                 <p><strong>Employment Type:</strong> {job.employmentType}</p>
// //                 <p><strong>Location:</strong> {job.jobLocation.addressLocality}, {job.jobLocation.addressCountry}</p>
// //                 <p><strong>Description:</strong> {job.description}</p>
// //                 <p><strong>Date Posted:</strong> {job.datePosted}</p>
// //                 <p><strong>Apply By:</strong> {job.validThrough}</p>
// //                 <p><strong>Hiring Organization:</strong> {job.hiringOrganization.name}</p>
// //                 <p><strong>Salary:</strong> {job.baseSalary.currency} {job.baseSalary.value.value} {job.baseSalary.value.unitText}</p>
// //                 <p><strong>Job Location Type:</strong> {job.jobLocationType}</p>
// //                 <p><strong>Applicant Location Requirements:</strong> {job.applicantLocationRequirements.name}</p>
// //                 <p><strong>Experience Requirements:</strong> {job.experienceRequirements}</p>
// //                 <p><strong>Education Requirements:</strong> {job.educationRequirements}</p>
// //                 <p><strong>Qualifications:</strong> {job.qualifications}</p>
// //                 <p><strong>Skills:</strong> {job.skills}</p>
// //                 <p><strong>Industry:</strong> {job.industry}</p>
// //                 <p><strong>Incentives:</strong> {job.incentives}</p>
// //                 <p><strong>Responsibilities:</strong> {job.responsibilities}</p>
// //                 <p><strong>Work Hours:</strong> {job.workHours}</p>
// //                 <p><strong>Job Benefits:</strong> {job.jobBenefits}</p>
// //                 <script type="application/ld+json">
// //                     {JSON.stringify(jobPostingSchema)}
// //                 </script>
// //             </div>
// //         </div>
// //     );
// // };

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const JobDetails = () => {
    const { id } = useParams();
    const [job, setJob] = useState(null);

    useEffect(() => {
        const fetchJob = async () => {
            try {
                const response = await axios.get(`https://gboard.onrender.com/job/${id}`);
                setJob(response.data);
            } catch (err) {
                console.error('Error fetching job details:', err);
            }
        };
        fetchJob();
    }, [id]);

    if (!job) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="text-xl font-semibold">Loading...</div>
            </div>
        );
    }

    const formatDate = (dateString) => {
        return dateString.split('T')[0];
    };

    return (
        <div className="py-8 px-4 sm:px-6 lg:px-8 bg-gray-50 min-h-screen">

            <script type="application/ld+json">
                {`
                        {
                            "@context": "http://schema.org",
                            "@type": "JobPosting",
                            "title": "${job.title}",
                            "description": "${job.description}",
                            "identifier": {
                                "@type": "PropertyValue",
                                "name": "${job.identifier.name}",
                                "value": "${job.identifier.value}"
                            },
                            "datePosted": "${formatDate(job.datePosted)}",
                            "validThrough": "${formatDate(job.validThrough)}",
                            "employmentType": "${job.employmentType}",
                            "hiringOrganization": {
                                "@type": "Organization",
                                "name": "${job.hiringOrganization.name}",
                                "sameAs": "${job.hiringOrganization.sameAs}"
                            },
                            "jobLocation": {
                                "@type": "Place",
                                "address": {
                                    "@type": "PostalAddress",
                                    "streetAddress": "${job.jobLocation.streetAddress}",
                                    "addressLocality": "${job.jobLocation.addressLocality}",
                                    "addressRegion": "${job.jobLocation.addressRegion}",
                                    "postalCode": "${job.jobLocation.postalCode}",
                                    "addressCountry": "${job.jobLocation.addressCountry}"
                                }
                            },
                            "baseSalary": {
                                "@type": "MonetaryAmount",
                                "currency": "${job.baseSalary.currency}",
                                "value": {
                                    "@type": "QuantitativeValue",
                                    "value": ${job.baseSalary.value.value},
                                    "unitText": "${job.baseSalary.value.unitText}"
                                }
                            },
                            "jobLocationType": "${job.jobLocationType}",
                            "applicantLocationRequirements": {
                                "@type": "Country",
                                "name": "${job.applicantLocationRequirements.name}"
                            },
                            "experienceRequirements": "${job.experienceRequirements}",
                            "educationRequirements": "${job.educationRequirements}",
                            "qualifications": "${job.qualifications}",
                            "skills": "${job.skills}",
                            "industry": "${job.industry}",
                            "incentives": "${job.incentives}",
                            "responsibilities": "${job.responsibilities}",
                            "workHours": "${job.workHours}",
                            "jobBenefits": "${job.jobBenefits}"
                        }
                    `}
            </script>
            <div className="max-w-4xl mx-auto p-8 bg-white border border-gray-200 shadow-lg rounded-lg">
                <h2 className="text-3xl font-bold mb-6 text-gray-800">{job.title}</h2>
                <div className="space-y-4">
                    <p className="text-md text-gray-700"><strong>ID:</strong> {job.identifier.value}</p>
                    <p className="text-md text-gray-700"><strong>Employment Type:</strong> {job.employmentType}</p>
                    <p className="text-md text-gray-700"><strong>Location:</strong> {job.jobLocation.addressLocality}, {job.jobLocation.addressCountry}</p>
                    <p className="text-md text-gray-700"><strong>Description:</strong> {job.description}</p>
                    <p className="text-md text-gray-700"><strong>Date Posted:</strong> {formatDate(job.datePosted)}</p>
                    <p className="text-md text-gray-700"><strong>Apply By:</strong> {formatDate(job.validThrough)}</p>
                    <p className="text-md text-gray-700"><strong>Hiring Organization:</strong> {job.hiringOrganization.name}</p>
                    <p className="text-md text-gray-700"><strong>Salary:</strong> {job.baseSalary.currency} {job.baseSalary.value.value} {job.baseSalary.value.unitText}</p>
                    <p className="text-md text-gray-700"><strong>Job Location Type:</strong> {job.jobLocationType}</p>
                    {/* <p className="text-lg text-gray-700"><strong>Applicant Location Requirements:</strong> {job.applicantLocationRequirements.name}</p> */}
                    <p className="text-md text-gray-700"><strong>Experience Requirements:</strong> {job.experienceRequirements}</p>
                    <p className="text-md text-gray-700"><strong>Education Requirements:</strong> {job.educationRequirements}</p>
                    <p className="text-md text-gray-700"><strong>Qualifications:</strong> {job.qualifications}</p>
                    <p className="text-md text-gray-700"><strong>Skills:</strong> {job.skills}</p>
                    <p className="text-md text-gray-700"><strong>Industry:</strong> {job.industry}</p>
                    <p className="text-md text-gray-700"><strong>Incentives:</strong> {job.incentives}</p>
                    <p className="text-md text-gray-700"><strong>Responsibilities:</strong> {job.responsibilities}</p>
                    <p className="text-md text-gray-700"><strong>Work Hours:</strong> {job.workHours}</p>
                    <p className="text-md text-gray-700"><strong>Job Benefits:</strong> {job.jobBenefits}</p>
                </div>
                <div className='flex justify-center items-center pt-5 pb-2'>
                    <a href="https://www.sproutsai.com/" className="font-bold mt-6 flex items-center justify-center w-full md:w-1/5 text-center py-3 text-white bg-blue-700 hover:bg-blue-600 rounded-lg shadow-md hover:scale-105 transition duration-300">
                        Apply
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4 ml-2"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M13 7l5 5m0 0l-5 5m5-5H6"
                            />
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default JobDetails;
