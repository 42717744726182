// import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom'; // Import Link
// import axios from 'axios';

// const JobList = () => {
//     const [jobs, setJobs] = useState([]);

//     useEffect(() => {
//         const fetchJobs = async () => {
//             try {
//                 const response = await axios.get('http://localhost:3000/jobs');
//                 setJobs(response.data);
//             } catch (err) {
//                 console.error('Error fetching jobs:', err);
//             }
//         };
//         fetchJobs();
//     }, []);

//     return (
//         <div className="relative flex flex-col items-center justify-center overflow-hidden bg-[#F3F5F9] p-6 sm:py-8">
//             <div className="bg-[#F3F5F9] w-full max-w-4xl px-5 py-4 rounded-md">
//                 {/* <h2 className="font-bold text-3xl text-center mb-8">Job Listings</h2> */}
//                 <div className="flex flex-col gap-6">
//                     {jobs.map(job => (
//                         <div key={job._id} className="bg-white shadow-xl shadow-gray-100 w-full flex flex-col gap-4 px-6 py-6 rounded-lg hover:shadow-2xl transition-shadow duration-300">
//                             <div>
//                                 <h3 className="font-bold text-2xl">{job.title}</h3>
//                                 <div className="flex flex-col gap-2 mt-4">
//                                     <span className="bg-blue-100 text-blue-600 rounded-full px-3 py-1 text-sm">{job.identifier.value}</span>
//                                     <span className="bg-blue-100 text-blue-600 rounded-full px-3 py-1 text-sm">{job.employmentType}</span>
//                                     <span className="text-slate-600 text-sm flex items-center gap-1">
//                                         <svg
//                                             xmlns="http://www.w3.org/2000/svg"
//                                             className="h-4 w-4"
//                                             fill="none"
//                                             viewBox="0 0 24 24"
//                                             stroke="currentColor"
//                                             strokeWidth={2}
//                                         >
//                                             <path
//                                                 strokeLinecap="round"
//                                                 strokeLinejoin="round"
//                                                 d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
//                                             />
//                                             <path
//                                                 strokeLinecap="round"
//                                                 strokeLinejoin="round"
//                                                 d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
//                                             />
//                                         </svg>
//                                         {job.jobLocation.addressLocality}, {job.jobLocation.addressCountry}
//                                     </span>
//                                 </div>
//                             </div>
//                             <div>
//                                 <Link
//                                     to={`/career/${job._id}`} // Use Link for navigation
//                                     className="bg-blue-600 text-white rounded font-medium px-4 py-2 flex gap-1 items-center mt-4 hover:bg-blue-700 transition-colors duration-300"
//                                 >
//                                     Display
//                                     <svg
//                                         xmlns="http://www.w3.org/2000/svg"
//                                         className="h-4 w-4"
//                                         fill="none"
//                                         viewBox="0 0 24 24"
//                                         stroke="currentColor"
//                                         strokeWidth={2}
//                                     >
//                                         <path
//                                             strokeLinecap="round"
//                                             strokeLinejoin="round"
//                                             d="M13 7l5 5m0 0l-5 5m5-5H6"
//                                         />
//                                     </svg>
//                                 </Link>
//                             </div>
//                         </div>
//                     ))}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default JobList;
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link
import axios from 'axios';

const JobList = () => {
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const response = await axios.get('https://gboard.onrender.com/jobs');
                setJobs(response.data);
            } catch (err) {
                console.error('Error fetching jobs:', err);
            }
        };
        fetchJobs();
    }, []);

    return (

        <div className="relative flex flex-col items-center justify-center overflow-hidden pt-16 pb-12  bg-[#F3F5F9] px-6 sm:py-8">
            <div className="bg-[#F3F5F9] w-full max-w-4xl px-5 pb-10 pt-14 rounded-md">
                {/* <h2 className="font-bold text-3xl text-center mb-8">Job Listings</h2> */}
                <div className="flex flex-col gap-6">
                    {jobs.map(job => (
                        <div key={job._id} className="bg-white shadow-md w-full flex items-center justify-between px-6 py-4 rounded-lg hover:shadow-xl transition-shadow duration-300">
                            <div className="flex flex-col gap-1">
                                {/* <span className="text-blue-600 text-sm">{job.department}</span> */}
                                <h3 className="font-bold text-xl">{job.title}</h3>
                                <div className="flex items-center gap-3 mt-2">
                                    <span className="bg-blue-100 text-blue-600 rounded-full px-3 py-1 font-semibold text-sm">{job.employmentType}</span>
                                    <span className="text-slate-600 text-sm flex items-center gap-1">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-4 w-4"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                            />
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                            />
                                        </svg>
                                        {job.jobLocation.addressLocality}, {job.jobLocation.addressCountry}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <Link
                                    to={`/career/${job._id}`} // Use Link for navigation
                                    className="bg-blue-600 text-white rounded font-medium px-4 py-2 flex gap-1 items-center hover:bg-blue-700 transition-colors duration-300"
                                >
                                    Apply Now
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4 w-4"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M13 7l5 5m0 0l-5 5m5-5H6"
                                        />
                                    </svg>
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="py-5">

            </div>
        </div>
    );
};

export default JobList;
