import React from 'react'

function Support() {
  return (
    
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Support</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-semibold mb-2">Support Hours</h2>
          <p>Monday - Friday: 9 AM to 5 PM</p>
        </div>
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-semibold mb-2">First Response SLA</h2>
          <p>Within 24 hours</p>
        </div>
      </div>
      <div className="mt-8">
        <h2 className="text-2xl font-bold mb-4">Support Options</h2>
        <ul className="space-y-4">
          {/* <li>
            <a href="/create-support-case" className="text-blue-500 hover:underline">Create a Support Case</a>
          </li> */}
          <li>
            <a href="mailto:niraj@sproutsai.com" className="text-blue-500 hover:underline">Email Support</a>
          </li>
          {/* <li>
            <a href="/knowledge-base" className="text-blue-500 hover:underline">Knowledge Base / Forums</a>
          </li> */}
          <li>
          Live Support Channel : SproutsAIBot
          </li>
          <li>Phone Support: +1 (408) 799 8605 </li>
        </ul>
      </div>
    </div>
  );
};


export default Support