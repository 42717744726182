import React, { useEffect, useState } from 'react';
import Subscribe from './Subscribe';
import HomepageLogo from '../components/assets/HomepageLogo.png'
import { useInView } from "react-intersection-observer";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from 'react-router';
import YouTube from 'react-youtube';
import CustomButton from '../components/layouts/CustomButton';


export default function Home() {

  const nav = useNavigate()
  const slides = [
    'http://dogsdiscovered.com/wp-content/uploads/2011/03/growling-dog.jpg',
    'https://pbs.twimg.com/profile_images/498611745100156928/1x9OADbM_400x400.jpeg',
    'https://pbs.twimg.com/profile_images/473535319090819074/6j-F-_N-_400x400.jpeg',
    'https://cdn2.bigcommerce.com/server3800/b0iqeya/product_images/uploaded_images/wet-dog.png?t=1409100694',
    'https://4.bp.blogspot.com/_4vn24YA-Rc4/Sm6yCChb-PI/AAAAAAAAA8Q/gS5WgIYb754/s400/dogs+color+vision.PNG',
  ];

  const [activeSlide, setActiveSlide] = useState(0);

  const nextSlide = () => {
    setActiveSlide((activeSlide + 1) % slides.length);
  };

  const prevSlide = () => {
    setActiveSlide((activeSlide - 1 + slides.length) % slides.length);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const CompanyImages = [

    "images/Vectors-Wrapper_3.svg",
    "images/Vectors-Wrapper_4.svg",
    "images/Vectors-Wrapper_5.svg",
    // "images/Vectors-Wrapper_6.svg",
    "images/Vectors-Wrapper_7.svg",
    "images/Vectors-Wrapper_8.svg",
    "images/Vectors-Wrapper_9.svg",
    "images/Vectors-Wrapper_10.svg",
    "images/AlphaNumero.svg",
    "images/GlobalFair.svg",
    "images/NiralNetwork.svg",
    "images/Yatra.svg",
    "https://assets-global.website-files.com/6483d3cf1f5331b6fb64ca95/656e3712c6aec649604c576a_62f2875a1df594216b9ad773_Microsoft_Azure_Logo.svg.png",
    "https://st2.zoom.us/static/6.3.17955/image/new/topNav/Zoom_logo.svg",
    "https://insights.virti.com/content/images/2021/09/20181218-Nvidia-Inception.png"

  ]


  function fetchPublicJobs() {
    const requestOptions = {
      method: 'POST',
      redirect: 'follow',
    };
    const body = {
      "firstname": "Pankaj",
      "lastname": "Chouhan",
      "name": "Pankaj Chouhan",
      "phone": "8770631461",
      "message": "My name is pankaj",
      "forReq": "demo",
      "subject": "Demo Request - SproutsAI",
      "email": "pankaj@sproutsai.com",
      "to": "pankaj@sproutsai.com"
    }

    fetch("https://api.sproutsai.com/controls/send-demo-mail", requestOptions)
      .then(response => response.json())
      .then(result => console.log())
      .catch(error => console.log('error', error));
  }

  const [ref1, inView1] = useInView({
    triggerOnce: false, // Only trigger once
  });
  const [ref2, inView2] = useInView({
    triggerOnce: false, // Only trigger once
  });
  const [ref3, inView3] = useInView({
    triggerOnce: false, // Only trigger once
  });
  const [ref4, inView4] = useInView({
    triggerOnce: false, // Only trigger once
  });
  const [ref5, inView5] = useInView({
    triggerOnce: false, // Only trigger once
  });
  const [ref6, inView6] = useInView({
    triggerOnce: false, // Only trigger once
  });


  const opts = {
    height: '458',
    width: '814',
    playerVars: {
      autoplay: 0, // Change to 1 if you want the video to autoplay
    },
  };

  const onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo(); // You can perform actions when the video is ready here
  };

  const calculateWidth = () => {
    // Calculate the width dynamically based on the aspect ratio
    const screenWidth = window.innerWidth;
    const aspectRatio = 814 / 458; // Width / Height
    const newWidth = screenWidth > 1200 ? 600 : screenWidth - 20; // Adjust the breakpoint if needed
    const newHeight = newWidth / aspectRatio;
    return { width: newWidth, height: newHeight };
  };

  const { width, height } = calculateWidth();

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (


    <div className='bg-[#F3F5F9] pb-20'>
      <Subscribe isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />

      {/* ref={ref} className={`bg-[#F3F5F9] your-element py-20 ${inView ? "fade" : ""}` */}

      <div ref={ref1} className={`flex justify-center bg-[#F3F5F9] your-element  ${inView1 ? "fade" : ""}`}>
        <div className="flex flex-col justify-center items-center lg:flex-row     max-w-screen-xl   px-4 sm:px-6 lg:px-8 py-[40px] lg:py-[70px]">

          <div className="w-full  md:w-full lg:w-3/6 sm:w-full">
            <div className="container mx-auto h-auto ">

              <header className="container   lg:flex lg:mt-10 items-center h-full ">
                <div className="w-full flex flex-col justify-center items-center lg:block ">
                  <h1 className=" text-[30px] md:text-[40px] lg:text-[50px] not-italic leading-[80px] font-bold ">

                    Autonomous  <span className=" text-blue-500">Agent </span> For
                  </h1>

                  <div>
                    <Carousel autoPlay={true} axis={'vertical'} autoFocus={true} transitionTime={1200} showThumbs={false} showStatus={false} interval={1500} emulateTouch={true} infiniteLoop={true} showIndicators={false} stopOnHover={false} >
                      <div className='flex justify-center lg:justify-start '>
                        <h1 className='text-[30px] md:text-[40px] lg:text-[50px] not-italic  font-bold'>Hiring automation</h1>
                      </div>
                      <div className='flex text-left justify-center lg:justify-start'>
                        <h1 className='text-[30px] md:text-[40px] lg:text-[50px] not-italic  font-bold'>Data enrichment</h1>
                      </div>
                      <div className='flex text-left justify-center lg:justify-start'>
                        <h1 className='text-[30px] md:text-[40px] lg:text-[50px] not-italic  font-bold'>Document processing</h1>
                      </div>
                    </Carousel>
                  </div>

                  {/* <p className='text-[20px] font-normal not-italic leading-[26px] mt-[20px]'>Always Be Closing. Automate the Funnel</p> */}

                  <div>
                    <p className="text-[18px] text-gray-600 font-normal not-italic leading-[26px] mt-[20px] font-regular">
                      SproutsAI offers an end-to-end autonomous AI agent platform to help automate critical business processes. Our cutting-edge technology combines the power of generative AI with proprietary models to deliver customized solutions that drive productivity, growth, and revenue.

                    </p>
                  </div>


                  <div className='mt-[30px]' />
                  <CustomButton text={"Get a Demo"} onClick={() => nav("/contact" + "?demo")} />
                </div>
              </header>
            </div>
          </div>






          <div className="hidden lg:block md:block md:w-full lg:w-3/6 sm:w-full mt-[50px] lg:mt-0 ">
            <img
              src={HomepageLogo}
              alt="Leafs"
              className="  h-[500px] object-contain rounded-[12px]"
            />
          </div>


        </div>
      </div>





      <div ref={ref2} className={`flex justify-center dark:bg-gray-800  bg-gray-800 your-element  ${inView2 ? "fade" : ""}`}>
        <div className="flex items-center max-w-screen-xl px-4 sm:px-6 lg:px-8 py-[70px]">
          <div class="overflow-hidden rounded-[12px] flex justify-between bg-gray-900  dark:bg-gray-900 lg:flex w-full lg:shadow-md">

            <div class="px-6 flex flex-col py-6 lg:py-[0px] justify-center w-full  lg:w-[40%] ">
              <h2 class="text-2xl md:text-3xl lg:text-4xl font-semibold  text-white dark:text-white">
                Meet Hiring Studio: <span class="text-blue-500"> <br /> Your AI-powered Recruiter</span>
              </h2>

              <p class="mt-4 text-gray-300 dark:text-gray-300">
                Hiring Studio is the world's first AI-powered recruiter
                providing sourcing and recruiting for enterprises and
                growing businesses. Hire 10x faster and cheaper and save 1000+ hours on top-tier hires.
              </p>

              <div class="inline-flex w-full mt-6 sm:w-auto">

                <CustomButton text={"Get a Demo"} onClick={e => nav("/contact")} />

              </div>
            </div>

            <div class="hidden lg:block w-full lg:w-[49%]">
              {/* <div className="xl:h-[auto] bg-cover" style={{ backgroundImage: 'url(./images/dashboard.png)' }}></div> */}
              {/* <img src="./images/dashboard.png" alt="" className="object-cover " /> */}
              <YouTube videoId={"cMURo3lCVh4"} opts={{
                ...opts,
                width: `${width}`,
                height: `${height}`,
              }} onReady={onReady}

                title={"SproutsAi demo"}
              />

            </div>
          </div>
        </div>
      </div>

      <section ref={ref3} className={`bg-gray-900 dark:bg-gray-900  your-element  ${inView3 ? "fade" : ""}`}>
        <div class="container px-6 py-10 mx-auto">
          <div class="flex items-center flex-col">
            <div className=''>
              <h1 class="text-2xl font-semibold text-white capitalize lg:text-3xl dark:text-white">
                Why we are different:
              </h1>

              <div class="mt-2">
                <span class="inline-block w-40 h-1 bg-blue-500 rounded-full"></span>
                <span class="inline-block w-3 h-1 ml-1 bg-blue-500 rounded-full"></span>
                <span class="inline-block w-1 h-1 ml-1 bg-blue-500 rounded-full"></span>
              </div>
            </div>

            <div className='lg:flex lg:items-center mt-[60px]'>
              <div class="w-full space-y-12 lg:w-1/2 ">


                <div class="md:flex md:items-start md:-mx-4">
                  <span class="inline-block p-2  rounded-xl md:mx-4 text-white bg-blue-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                      />
                    </svg>
                  </span>
                  <div class="mt-4 md:mx-4 md:mt-0">
                    <h1 class="text-xl font-semibold capitalize text-white">
                      Real time job modeling and candidate matching
                    </h1>

                    <p class="mt-3 text-gray-300">
                      Find the high quality candidate match in minutes and speed up your hiring by 10x
                    </p>
                  </div>
                </div>

                <div class="md:flex md:items-start md:-mx-4">
                  <span class="inline-block p-2 rounded-xl md:mx-4 text-white bg-blue-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                      />
                    </svg>
                  </span>

                  <div class="mt-4 md:mx-4 md:mt-0">
                    <h1 class="text-xl font-semibold text-white">
                      Increase hiring team’s productivity
                    </h1>

                    <p class="mt-3 text-gray-300">
                      AI recommended candidates and recruiter grade multichannel outreach and scheduling    </p>
                  </div>
                </div>

                <div class="md:flex md:items-start md:-mx-4">
                  <span class="inline-block p-2 rounded-xl md:mx-4 text-white bg-blue-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z"
                      />
                    </svg>
                  </span>

                  <div class="mt-4 md:mx-4 md:mt-0">
                    <h1 class="text-xl font-semibold  capitalize text-white">

                      Easy to get started
                    </h1>

                    <p class="mt-3 text-gray-300">
                      Start your journey with easy to use platform that provides faster time to value
                    </p>
                  </div>
                </div>
                <div class="md:flex md:items-start md:-mx-4">
                  <span class="inline-block p-2 rounded-xl md:mx-4 text-white bg-blue-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z"
                      />
                    </svg>
                  </span>

                  <div class="mt-4 md:mx-4 md:mt-0">
                    <h1 class="text-xl font-semibold capitalize text-white">
                      Personalized as per Your need
                    </h1>

                    <p class="mt-3 text-gray-300">
                      Our advanced AI continually evolves and refines its capabilities, adapting over time to consistently deliver superior results. With every iteration, it propels your outcomes to new heights
                    </p>
                  </div>
                </div>
              </div>

              <div class="hidden lg:flex lg:items-center lg:w-1/2 lg:justify-center">
                <img
                  // class="w-[28rem] h-[28rem] object-cover xl:w-[34rem] xl:h-[34rem] rounded-full"
                  class="w-[28rem] h-[28rem] object-scale-down xl:w-[34rem] xl:h-[34rem] rounded-full"
                  src="images/wearedifferent2.png"
                  alt=""
                />
              </div>
            </div>
          </div>

          <hr class="my-12 border-gray-200 " />
        </div>
      </section>




      <div className='flex justify-center items-center py-[70px] flex-col lg:hidden md:hidden'>
        <div className='ml-8 mt-11 mb-12'>
          <h1 class="text-2xl font-semibold text-gray-800 capitalize lg:text-3xl ">
            See SproutsAI in Motion:
          </h1>

          <div class="">
            <span class="inline-block w-40 h-1 bg-blue-500 rounded-full"></span>
            <span class="inline-block w-3 h-1 ml-1 bg-blue-500 rounded-full"></span>
            <span class="inline-block w-1 h-1 ml-1 bg-blue-500 rounded-full"></span>
          </div>
        </div>


        <YouTube videoId={"cMURo3lCVh4"} opts={{
          ...opts,
          width: `${width}`,
          height: `${height}`,
        }} onReady={onReady}

          title={"SproutsAi demo"}
        />

      </div>





      <div ref={ref5} className={`flex justify-center your-element  ${inView5 ? "fade" : ""}`}>
        <div className="logo-title w-full grid grid-cols max-w-screen-xl  gap-x-6 py-10  justify-start items-center  px-0 ">
          <h1 className=" text-center mt-0 mb-14 text-2xl md:text-3xl lg:text-4xl font-bold text-gray-700 leading-10">We are trusted by leading companies</h1>
          <div className=''>
            <div className=" flex justify-center ">
              <Carousel autoFocus={true} transitionTime={1000} autoPlay={true} showThumbs={false} showStatus={false} interval={1500} emulateTouch={true} infiniteLoop={true} showIndicators={false} stopOnHover={false} centerMode={true} centerSlidePercentage={25} >
                {CompanyImages?.map((item, index) => (
                  <div key={index}>
                    <img src={item} alt={`image ${index}`} className='w-[100px] h-[15px] md:h-[30px] lg:h-[30px]  object-contain mr-[20px]' />
                  </div>
                ))}

                <div >
                  <span className='w-[100px] h-[15px] md:h-[30px] lg:h-[30px]  object-contain mr-[20px]'>
                  
                  </span>
                </div>


              </Carousel>
            </div>
          </div>
        </div>
      </div>



      <div ref={ref4} className={`flex justify-center bg-[#F3F5F9] your-element  ${inView4 ? "fade" : ""}`}>
        <div className="flex items-center flex-col   max-w-screen-xl   px-4 sm:px-6 lg:px-8 py-[70px]">
          <div className='ml-8 mt-11 '>
            <h1 class="text-2xl font-semibold text-gray-800 capitalize lg:text-3xl ">
              Benefits of using SproutsAI:
            </h1>

            <div class="">
              <span class="inline-block w-40 h-1 bg-blue-500 rounded-full"></span>
              <span class="inline-block w-3 h-1 ml-1 bg-blue-500 rounded-full"></span>
              <span class="inline-block w-1 h-1 ml-1 bg-blue-500 rounded-full"></span>
            </div>
          </div>



          <section>
            <div class="relative items-center w-full px-5 py-12 mx-auto md:px-12 lg:px-24 max-w-7xl">
              <div class="grid w-full grid-cols-1 gap-12 mx-auto lg:grid-cols-3">
                <div class="p-6  bg-[#FFF] rounded-[12px] hover-item">
                  <div class="inline-flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto mb-5 text-blue-600 rounded-full bg-blue-50">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 icon icon-tabler icon-tabler-aperture" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <circle cx="12" cy="12" r="9"></circle>
                      <line x1="3.6" y1="15" x2="14.15" y2="15"></line>
                      <line x1="3.6" y1="15" x2="14.15" y2="15" transform="rotate(72 12 12)"></line>
                      <line x1="3.6" y1="15" x2="14.15" y2="15" transform="rotate(144 12 12)"></line>
                      <line x1="3.6" y1="15" x2="14.15" y2="15" transform="rotate(216 12 12)"></line>
                      <line x1="3.6" y1="15" x2="14.15" y2="15" transform="rotate(288 12 12)"></line>
                    </svg>
                  </div>
                  <h1 class="mx-auto mb-8 text-2xl font-semibold leading-none tracking-tighter text-neutral-600 lg:text-3xl">Organizations</h1>
                  <p class="mx-auto text-base leading-relaxed text-gray-500">
                    24*7 Hiring, anywhere speed up hiring by 10x
                    <br />
                    <br />
                    Hiring better candidates faster
                    <br />
                    <br />
                    Data Driven & Optimized

                  </p>
                </div>
                <div class="p-6 bg-[#FFF] rounded-[12px] hover-item">
                  <div class="inline-flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto mb-5 text-blue-600 rounded-full bg-blue-50">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 icon icon-tabler icon-tabler-aperture" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <circle cx="12" cy="12" r="9"></circle>
                      <line x1="3.6" y1="15" x2="14.15" y2="15"></line>
                      <line x1="3.6" y1="15" x2="14.15" y2="15" transform="rotate(72 12 12)"></line>
                      <line x1="3.6" y1="15" x2="14.15" y2="15" transform="rotate(144 12 12)"></line>
                      <line x1="3.6" y1="15" x2="14.15" y2="15" transform="rotate(216 12 12)"></line>
                      <line x1="3.6" y1="15" x2="14.15" y2="15" transform="rotate(288 12 12)"></line>
                    </svg>
                  </div>
                  <h1 class="mx-auto mb-8 text-2xl font-semibold leading-none tracking-tighter text-neutral-600 lg:text-3xl">Recruiter</h1>
                  <p class="mx-auto text-base leading-relaxed text-gray-500">
                    10x Productivity Increase
                    <br />
                    <br />
                    Identify top talents in near real-time
                    <br />
                    <br />
                    Eliminates bias


                  </p>
                </div>
                <div class="p-6 bg-[#FFF] rounded-[12px] hover-item">
                  <div class="inline-flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto mb-5 text-blue-600 rounded-full bg-blue-50">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 icon icon-tabler icon-tabler-aperture" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <circle cx="12" cy="12" r="9"></circle>
                      <line x1="3.6" y1="15" x2="14.15" y2="15"></line>
                      <line x1="3.6" y1="15" x2="14.15" y2="15" transform="rotate(72 12 12)"></line>
                      <line x1="3.6" y1="15" x2="14.15" y2="15" transform="rotate(144 12 12)"></line>
                      <line x1="3.6" y1="15" x2="14.15" y2="15" transform="rotate(216 12 12)"></line>
                      <line x1="3.6" y1="15" x2="14.15" y2="15" transform="rotate(288 12 12)"></line>
                    </svg>
                  </div>
                  <h1 class="mx-auto mb-8 text-2xl font-semibold leading-none tracking-tighter text-neutral-600 lg:text-3xl">Candidate </h1>
                  <p class="mx-auto text-base leading-relaxed text-gray-500">
                    Better interview match
                    <br />
                    <br />
                    Real-time job search, interview and feedback
                    <br />
                    <br />
                    Significantly improve candidate search exp.

                  </p>
                </div>
              </div>
            </div>
          </section>

        </div>
      </div>

      <div ref={ref6} className={`bg-[#FFF] hover-item w-[80%] lg:w-[60%] md:w-[60%] mx-auto p-4 mb-0 rounded-[12px] mt-[40px]  shadow-CardsShadow your-element  ${inView6 ? "fade" : ""}`}>
        <img src="images/profilePic.png" className="w-10 h-10 m-auto mb-8 rounded-sm" />
        <p className="text-gray-500  w-full md:w-2/3 m-auto text-center text-lg md:text-md">
          <span className="font-bold text-indigo-500">“</span>
          SproutsAI has revolutionized our hiring process!
          Their AI platform effortlessly attracts top talent with tailored job descriptions
          and automates screening calls. Thanks to SproutsAI, we found our dream team in no time.
          Highly recommended!
          <span className="font-bold text-indigo-500">”</span>
        </p>
        <div className="flex items-center justify-center mt-8">

          <div className="flex items-center justify-center ml-2">
            <span className="mr-2 text-lg font-semibold text-indigo-500">
              - Emily
            </span>
            {/* <span className="text-xl font-light text-gray-400">/</span>
            <span className="ml-2 text-gray-400 text-md">User of Tail-Kit</span> */}
          </div>
        </div>
      </div>

    </div>


  )
}
