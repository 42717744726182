import React from "react";

export default function Integrations() {


  const imageUrls = [
    "https://merge-api-production.s3.amazonaws.com/media/applicantstack.svg",
    "https://merge-api-production.s3.amazonaws.com/media/ashby_A0gQsIn.svg",
    "https://merge-api-production.s3.amazonaws.com/media/bamboohr.svg",
    "https://merge-api-production.s3.amazonaws.com/media/breezy.svg",
    "https://merge-api-production.s3.amazonaws.com/media/bullhorn_white.svg",
    "https://merge-api-production.s3.amazonaws.com/media/cats_white.svg",
    "https://merge-api-production.s3.amazonaws.com/media/ClayHR_white.svg",
    "https://merge-api-production.s3.amazonaws.com/media/clockwork.svg",
    "https://merge-api-production.s3.amazonaws.com/media/comeet_TIDetrj.svg",
    "https://merge-api-production.s3.amazonaws.com/media/cornerstone_white.svg",
    "https://merge-api-production.s3.amazonaws.com/media/Engage_ATS_white.svg",
    "https://merge-api-production.s3.amazonaws.com/media/eploy_white_wbUxAtW.svg",
    "https://merge-api-production.s3.amazonaws.com/media/fountain_white.svg",
    "https://merge-api-production.s3.amazonaws.com/media/Freshteam_white.svg",
    "https://merge-api-production.s3.amazonaws.com/media/greenhouse.svg",
    "https://merge-api-production.s3.amazonaws.com/media/greenhouse_white.svg",
    "https://merge-api-production.s3.amazonaws.com/media/hr-cloud.svg",
    "https://merge-api-production.s3.amazonaws.com/media/Harbour_white_fV3dcfK.svg",
    "https://merge-api-production.s3.amazonaws.com/media/homerun_KoHwtGy.svg",
    "https://merge-api-production.s3.amazonaws.com/media/infinite-1.svg",
    "https://merge-api-production.s3.amazonaws.com/media/jazzhr.svg",
    "https://merge-api-production.s3.amazonaws.com/media/jobadder_white.svg",
    "https://merge-api-production.s3.amazonaws.com/media/JobScore_Logo_White.svg",
    "https://merge-api-production.s3.amazonaws.com/media/jobsoid.svg",
    "https://merge-api-production.s3.amazonaws.com/media/jobvite_white.svg",
    "https://merge-api-production.s3.amazonaws.com/media/lano_jEfkihL.svg",
    "https://merge-api-production.s3.amazonaws.com/media/lever.svg",
    "https://merge-api-production.s3.amazonaws.com/media/white_oracle_recruiting.svg",
    "https://merge-api-production.s3.amazonaws.com/media/taleo_z8YGGc5.svg",
    "https://merge-api-production.s3.amazonaws.com/media/3.svg",
    "https://merge-api-production.s3.amazonaws.com/media/Polymer_White.svg",
    "https://merge-api-production.s3.amazonaws.com/media/recruitee.svg",
    "https://merge-api-production.s3.amazonaws.com/media/recruiterflow.svg",
    "https://merge-api-production.s3.amazonaws.com/media/Recruitive_white.svg",
    "https://merge-api-production.s3.amazonaws.com/media/successfactors.svg",
    "https://merge-api-production.s3.amazonaws.com/media/sage.svg",
    "https://merge-api-production.s3.amazonaws.com/media/smartrecruiters_ihwsoHF.svg",
    "https://merge-api-production.s3.amazonaws.com/media/talentlyft_mrj5V0p.svg",
    "https://merge-api-production.s3.amazonaws.com/media/TalentReef_white.svg",
    "https://merge-api-production.s3.amazonaws.com/media/teamtailor.svg",
    "https://merge-api-production.s3.amazonaws.com/media/ukg_white_fc9YbtU.svg",
    "https://merge-api-production.s3.amazonaws.com/media/workable.svg",
    "https://merge-api-production.s3.amazonaws.com/media/workday_uZ1qPH6.svg",
    "https://merge-api-production.s3.amazonaws.com/media/Zoho_Recruit_white.svg",
    "https://merge-api-production.s3.amazonaws.com/media/ICIMS_white.svg"
  ];

  const features = [
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
          />
        </svg>
      ),
      title: "Never miss a candidate",
      desc: "All Applicants and sourced prospects synced directly to ATS",
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
          />
        </svg>
      ),
      title: "Automatically posts jobs",
      desc: "New ATS jobs are posted to SproutsAI - no manual posting required",
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
          />
        </svg>
      ),
      title: "Synced pipelines",
      desc: "Candidates statuses are always synced between your ATS and SproutsAI",
    },
  ];

  return (
    <div className="bg-[#F3F5F9] py-20">
      <section className=" mx-auto max-w-screen-xl pb-4 px-4 sm:px-8">
        <div className="text-center space-y-4">
          <h1 className="text-gray-800 font-bold text-4xl md:text-5xl">
            Integrations
            {/* <span className="text-indigo-600"> Search engine</span> */}
          </h1>
          <p className="text-gray-500 max-w-xl mx-auto leading-relaxed">
            Out-of-the-box integrations with ATS and other platform
          </p>
        </div>
      </section>
      <section className="py-14">
        <div className="max-w-screen-xl mx-auto px-4 text-center text-gray-600 md:px-8">
          <div className="max-w-2xl mx-auto"></div>
          <div className="mt-12">
            <ul className="grid gap-y-8 gap-x-12 sm:grid-cols-2 lg:grid-cols-3">
              {features.map((item, idx) => (
                <li key={idx} className="space-y-3">
                  <div className="w-12 h-12 mx-auto bg-indigo-50 text-indigo-600 rounded-full flex items-center justify-center">
                    {item.icon}
                  </div>
                  <h4 className="text-lg text-gray-800 font-semibold">
                    {item.title}
                  </h4>
                  <p>{item.desc}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      <div className="py-14 ">
        <div className="max-w-screen-xl mx-auto px-4 md:px-8 ">

          <div className='flex items-center flex-col'>
            <div className=''>
              <h1 class="text-2xl font-semibold text-gray-800 capitalize lg:text-3xl ">
                Supported ATS and HCM Platforms:
              </h1>

              <div class="mt-2">
                <span class="inline-block w-40 h-1 bg-blue-500 rounded-full"></span>
                <span class="inline-block w-3 h-1 ml-1 bg-blue-500 rounded-full"></span>
                <span class="inline-block w-1 h-1 ml-1 bg-blue-500 rounded-full"></span>
              </div>
            </div>
          </div>
          <div className="mt-6">

            <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
              {imageUrls.map((item, idx) => (


                <div className="hover-item">
                  <div className="bg-blue-200 hover:bg-blue-500 h-[96px] p-4 flex items-center justify-center rounded-[8px]">
                    <img className="h-auto max-w-full rounded-lg" src={item} alt="" />
                  </div>
                </div>



              ))}
            </div>

          </div>
        </div>
      </div>

    </div>
  );
}
