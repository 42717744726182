import React from "react";

export default function IndustrySolution() {
  return (
    <div className="bg-[#F3F5F9] py-20">
      <section className=" mx-auto max-w-screen-xl pb-4 px-4 sm:px-8">
        <div className="text-center space-y-4">
          <h1 className="text-gray-800 font-bold text-4xl md:text-5xl">
            Industry
            <span className="text-indigo-600"> Solution</span>
          </h1>
        </div>
      </section>
      <section className="py-14">
        <div className="max-w-screen-xl mx-auto md:px-8">
          <div className="items-center gap-x-12 sm:px-4 md:px-0 lg:flex">
            <div className="flex-1 sm:hidden lg:block">
              <img
                src="images/aigen.png"
                className="md:max-w-lg h-80 sm:rounded-lg"
                alt=""
              />
            </div>
            <div className="max-w-xl px-4 space-y-3 mt-6 sm:px-0 md:mt-0 lg:max-w-2xl">
              <h3 className="text-blue-600 font-semibold">Technology</h3>
              <p className="text-gray-800 text-3xl font-semibold sm:text-4xl">
                Tech-Driven Recruitment: AI for Bias-Free Hiring Across
                Industries
              </p>
              <p className="mt-3 text-gray-600">
                Automated hiring solutions help technology companies streamline
                their hiring process, identify and recruit high qualified
                candidates faster, and reduce hiring bias.
              </p>
              <p className="mt-3 text-gray-600">
                Our AI-powered solution goes beyond the surface-level,
                aggregating billions of data points to enrich candidate profiles
                with missing skills and insights about their work experience.
              </p>
              <p className="mt-3 text-gray-600">
                We source across engineering, product, data, sales, marketing &
                more. Essentially all the roles, in all the locations.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-14">
        <div className="max-w-screen-xl mx-auto md:px-8">
          <div className="items-center gap-x-12 sm:px-4 md:px-0 lg:flex">
            <div className="flex-1 sm:hidden lg:block">
              <img
                src="images/aihel.png"
                className="md:max-w-lg sm:rounded-lg"
                alt=""
              />
            </div>
            <div className="max-w-xl px-4 space-y-3 mt-6 sm:px-0 md:mt-0 lg:max-w-2xl">
              <h3 className="text-indigo-600 font-semibold">Healthcare</h3>
              <p className="text-gray-800 text-3xl font-semibold sm:text-4xl">
                Healthcare Talent Oasis: Automated Solutions for Bias-Free
                Recruitment and Efficiency
              </p>
              <p className="mt-3 text-gray-600">
                Solution for healthcare and pharmaceutical Industry Automated
                hiring solutions help healthcare organizations streamline their
                hiring process, identify and recruit qualified candidates
                faster, and reduce hiring bias.
              </p>
              <p className="mt-3 text-gray-600">
                By automating tasks such as screening resumes, scheduling
                interviews, and conducting background checks, automated hiring
                solutions can free up recruiters' time so they can focus on more
                strategic tasks, such as building relationships with candidates
                and developing a strong employer brand.
              </p>
              {/* <a
                href="javascript:void(0)"
                className="inline-flex gap-x-1 items-center text-indigo-600 hover:text-indigo-500 duration-150 font-medium"
              >
                Career
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 10a.75.75 0 01.75-.75h10.638L10.23 5.29a.75.75 0 111.04-1.08l5.5 5.25a.75.75 0 010 1.08l-5.5 5.25a.75.75 0 11-1.04-1.08l4.158-3.96H3.75A.75.75 0 013 10z"
                    clipRule="evenodd"
                  />
                </svg>
              </a> */}
            </div>
          </div>
        </div>
      </section>
      {/* <section className="py-14">
        <div className="max-w-screen-xl mx-auto md:px-8">
          <div className="items-center gap-x-12 sm:px-4 md:px-0 lg:flex">
            <div className="flex-1 sm:hidden lg:block">
              <img
                src="images/resultscus.png"
                className="md:max-w-lg sm:rounded-lg"
                alt=""
              />
            </div>
            <div className="max-w-xl px-4 space-y-3 mt-6 sm:px-0 md:mt-0 lg:max-w-2xl">

              <p className="mt-3 text-gray-600">
              Kick-off your hiring process in seconds, not minutes. Create position profiles, interview questions, and exercises with the help of our GPT-3 powered AI. Say goodbye to tedious manual work and hello to efficient and effective hiring with Careers!
              </p>
              <p className="mt-3 text-gray-600">
              Optimize Your Hiring Process with Video Interviews with AI Analysis
              </p>

            </div>
          </div>
        </div>
      </section> */}
      
    </div>
  );
}
